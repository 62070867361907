import React, { useEffect, useState } from "react";
import classes from "./AppAPIKey.module.css";
import Sidebar from "../../components/ui/AppShell/Sidebar";
import { Box, Button, Flex, Modal, Pagination, rem, ScrollArea, Text, TextInput } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconCopyPlus, IconSearch } from "@tabler/icons-react";
import TableSkeleton from "../../components/ui/TableSkeleton";
import TableAppApiKey from "../../components/pages/appApiKey/TableAppApi";
import FormAppApi from "../../components/pages/appApiKey/FormAppApi";
import { getListAppApiKey } from "../../services/appApiKey";

const defaultParameter = {
  skip: 0,
  take: 10,
  keyword: "",
};

const labelTable = [
  {
    label: "No.",
    width: 30,
  },
  {
    label: "Nama API",
    width: "auto",
  },
  {
    label: "API Key",
    width: "auto",
  },
  {
    label: "Aplikasi",
    width: "auto",
  },
  {
    label: "Status",
    width: "auto",
  },
  {
    label: "Tgl Dibuat",
    width: "auto",
  },
  {
    label: "Action",
    width: 100,
  },
];

const AppAPIKey = () => {
  const [params, setParams] = useState(defaultParameter);
  const [appApiKeyList, setAppApiKeyList] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openModalForm, setOpenModalForm] = useState(false);
  const [detailData, setDetailData] = useState(null);
  const isSmallScreen = useMediaQuery("(max-width: 425px)");

  const handleGetAppApiKey = async () => {
    setLoading(true);
    try {
      const response = await getListAppApiKey(params);
      setAppApiKeyList(response.data);
      setCount(response.count);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetAppApiKey();
    // eslint-disable-next-line
  }, [params]);

  const handleSearchChange = (e) => {
    const params = {
      skip: 0,
      take: 10,
      keyword: e.target.value,
    };
    setParams(params);
  };

  const handleChangePage = (e) => {
    const params = {
      skip: (e - 1) * 10,
      take: 10,
      keyword: "",
    };
    setParams(params);
  };

  const onCloseModalForm = () => {
    setOpenModalForm(false);
    setDetailData(null);
  };

  const actions = {
    edit: (val) => {
      setOpenModalForm(true);
      setDetailData(val);
    },
  };

  const handleAction = (val, type) => {
    return actions[type](val);
  };

  return (
    <Sidebar>
      <Box>
        <Text className={classes.titlePage} mb={20}>
          App API Key Management
        </Text>
      </Box>

      <Box style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
        <Flex>
          <TextInput mr={8} leftSectionPointerEvents="none" leftSection=<IconSearch style={{ width: rem(16), height: rem(16) }} /> placeholder="Cari API. . ." name="search" onChange={handleSearchChange} />
        </Flex>
        <Flex mt={isSmallScreen ? 10 : 0}>
          <Button fullWidth={isSmallScreen} leftSection={<IconCopyPlus size={14} />} color="#0572b9" onClick={() => setOpenModalForm(true)}>
            Tambah API Key
          </Button>
        </Flex>
      </Box>
      <Box my={20}>{loading ? <TableSkeleton total={5} /> : <TableAppApiKey label={labelTable} data={appApiKeyList} countData={params.skip} actionMethod={handleAction} />}</Box>
      <Flex justify="end">
        <Pagination onChange={handleChangePage} total={Math.ceil(count / params.take) || 0} color="#0572b9" radius="lg" />
      </Flex>
      <Modal
        opened={openModalForm}
        onClose={onCloseModalForm}
        centered
        closeOnClickOutside={false}
        size="lg"
        title={<Text fw="Bold">{detailData ? "Edit App API Key" : "Tambah App API Key"}</Text>}
        scrollAreaComponent={ScrollArea.Autosize}
        withCloseButton={false}
      >
        <FormAppApi dataAppApi={detailData} onCloseModal={onCloseModalForm} reloadList={handleGetAppApiKey} />
      </Modal>
    </Sidebar>
  );
};

export default AppAPIKey;
