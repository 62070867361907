import React, { useEffect, useState } from "react";
import classes from "./feature.module.css";
import Sidebar from "../../components/ui/AppShell/Sidebar";
import { Box, Button, Card, Flex, Group, Skeleton, Text, TextInput, rem } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconSearch } from "@tabler/icons-react";
import { getListApplication } from "../../services/application";
import { IconChevronRight } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

const paramApp = {
  skip: 0,
  take: 10,
  keyword: "",
  orderBy: "name",
  order: "ASC",
};

const Feature = () => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width: 425px)");
  const [loadingForm, setLoadingForm] = useState(false);
  const [paramsApp, setParamsApp] = useState(paramApp);
  const [applicationList, setApplicationList] = useState([]);

  //   console.log(featureList);

  const handleGetListApplication = async () => {
    setLoadingForm(true);
    try {
      const response = await getListApplication(paramsApp);
      const dataApplication = response.data;
      const mappingApplication = dataApplication.map((val) => {
        return {
          value: val?.id?.toString(),
          label: val?.name,
        };
      });
      setApplicationList(mappingApplication);
    } catch (error) {
      console.log(error);
    }
    setLoadingForm(false);
  };

  useEffect(() => {
    handleGetListApplication();
    // eslint-disable-next-line
  }, [paramsApp]);

  const handleSearchApp = (e) => {
    const params = {
      skip: 0,
      take: 10,
      keyword: e.target.value,
      orderBy: "name",
      order: "ASC",
    };
    setParamsApp(params);
  };

  const seeApp = (val) => {
    navigate(`/features/${val?.label}/${val.value}`);
  };

  return (
    <Sidebar>
      <Box>
        <Text className={classes.titlePage} mb={20}>
          Feature Management
        </Text>
      </Box>
      <Box style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
        <Flex>
          <TextInput leftSectionPointerEvents="none" leftSection=<IconSearch style={{ width: rem(16), height: rem(16) }} /> placeholder="Cari app. . ." name="search" onChange={handleSearchApp} />
        </Flex>
      </Box>

      {!loadingForm ? (
        <>
          {applicationList?.map((el) => {
            return (
              <Box my={10}>
                <Card shadow="sm" padding="sm" radius="md" withBorder>
                  <Group justify="space-between" mt="md" mb="xs" mx="sm">
                    <Box>
                      <Text fw={500} fz={14}>
                        Application:
                      </Text>
                      <Text fw={800} fz={16}>
                        {el?.label}
                      </Text>
                    </Box>
                    <Button radius="lg" variant="outline" color="#0572b9" rightSection={<IconChevronRight size={14} />} onClick={() => seeApp(el)}>
                      Lihat Feature {el?.label}
                    </Button>
                  </Group>
                </Card>
              </Box>
            );
          })}
        </>
      ) : (
        <Box>
          <Skeleton my={10} height={70} width="100%" radius="md" mb="md" />
          <Skeleton my={10} height={70} width="100%" radius="md" mb="md" />
          <Skeleton my={10} height={70} width="100%" radius="md" mb="md" />
        </Box>
      )}
    </Sidebar>
  );
};

export default Feature;
