import { Box, Button, Flex, Group, TextInput } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { validation } from "../../../plugins/validation";
import { updateUserAccount } from "../../../services/users";
import { notificationError, notificationSuccess } from "../../ui/Notification";

const defaultVal = {
  fullName: "",
  email: "",
};

const formValidation = {
  fullName: {
    isError: false,
    message: "",
  },
  email: {
    isError: false,
    message: "",
  },
};

const FormEditProfile = ({ onCloseModal, profile, reload }) => {
  const [loadingForm, setLoadingForm] = useState(false);
  const [formData, setFormData] = useState(defaultVal);
  const [validationForm, setValidationForm] = useState(formValidation);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (profile !== null) {
      handleSetForm(profile);
    }
    // eslint-disable-next-line
  }, [profile]);

  const handleSetForm = (profile) => {
    const dataDetail = {
      fullName: profile?.fullName,
      email: profile?.email,
    };
    setFormData(dataDetail);
  };

  const handleSubmit = async (data) => {
    let methodFunction = null;
    let titleMessageSuccess = "";
    let captionMessageSuccess = "";
    let titleMessageError = "";
    let captionMessageError = "";
    setLoadingForm(true);
    setValidationForm(formValidation);
    const payload = {
      fullName: data.fullName,
      email: data.email,
    };
    const isError = validation(payload, setValidationForm);
    if (isError) {
      setLoadingForm(false);
      return;
    }
    if (profile !== null) {
      methodFunction = updateUserAccount(payload);
      titleMessageSuccess = "Update Profile Berhasil";
      captionMessageSuccess = "Anda telah berhasil mengupdate data Profile";
      titleMessageError = "Gagal Mengupdate Profile";
      captionMessageError = "Silahkan cek kembali form anda";
    }
    try {
      const response = await methodFunction;
      if (response) {
        onCloseModal();
        reload();
        notificationSuccess(titleMessageSuccess, captionMessageSuccess);
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      notificationError(titleMessageError, `${Object.keys(errorMessage) ? errorMessage : captionMessageError}`);
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el,
              },
            }));
          }
        });
      });
    } finally {
      setLoadingForm(false);
    }
  };

  return (
    <Box>
      <Box mb="md">
        <TextInput disabled name="role" value={profile.role.name} label="Role" placeholder="Masukkan role" />
      </Box>
      <Box mb="md">
        <TextInput
          name="fullName"
          value={formData.fullName}
          label="Nama Lengkap"
          placeholder="Masukkan nama lengkap"
          error={validationForm.fullName.isError ? `${validationForm.fullName.message}` : ""}
          onChange={handleChange}
          withAsterisk
        />
      </Box>
      <Box mb="md">
        <TextInput name="email" value={formData.email} label="Email" placeholder="Masukkan email" error={validationForm.email.isError ? `${validationForm.email.message}` : ""} onChange={handleChange} withAsterisk />
      </Box>

      <Box mt={20}>
        <Flex justify="flex-end">
          <Group>
            <Button variant="outline" color="indigo.9" onClick={onCloseModal}>
              Tutup
            </Button>
            <Button loading={loadingForm} onClick={() => handleSubmit(formData)} variant="filled" color="indigo.9">
              Simpan
            </Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  );
};

export default FormEditProfile;
