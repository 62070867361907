import http from "../plugins/axios";

export const getListSettingItem = async (params) => {
  try {
    const response = await http.get(`/setting-items`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDetailSettingItem = async (id) => {
  try {
    const response = await http.get(`/setting-items/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addSettingItem = async (payload) => {
  try {
    const response = await http.post(`/setting-items`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateSettingItem = async (id, payload) => {
  try {
    const response = await http.patch(`/setting-items/${id}`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};
