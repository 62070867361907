import { Accordion, Box, Button, Flex, Group, ScrollArea, Skeleton, Switch, Text } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { assignRolePermissionDefault, getListRolePermissionDefault } from "../../../services/roleDefault";
import { notificationError, notificationSuccess } from "../../ui/Notification";
import { getListPermissionDefault } from "../../../services/permissionDefault";

const FormRolePermission = ({ dataRoleDefault, onCloseModal }) => {
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [permissionList, setPermissionList] = useState(null);
  const [selectedPermissionRoleId, setSelectedPermissionRoleId] = useState([]);
  const appId = dataRoleDefault?.applicationRoleDefault?.[0]?.application?.id;
  console.log(permissionList);
  console.log(selectedPermissionRoleId);

  const handleGetPermissionList = async () => {
    setLoading(true);
    try {
      const response = await getListPermissionDefault(appId);
      setPermissionList(response);
      const detailRolePermission = await getListRolePermissionDefault(dataRoleDefault?.id, appId);
      console.log(detailRolePermission);
      const dataPermission = Object.values(detailRolePermission);
      const permissionId = [];
      for (let i = 0; i < dataPermission.length; i++) {
        const permissionData = dataPermission[i];
        for (let j = 0; j < permissionData.length; j++) {
          permissionId.push(permissionData[j]);
        }
      }
      setSelectedPermissionRoleId(permissionId);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleGetPermissionList();
    // eslint-disable-next-line
  }, []);

  const submitPermission = async (dataPermission) => {
    setLoadingSubmit(true);
    const payload = {
      // applicationId: appId,
      roleId: dataRoleDefault?.id,
      permissionIds: [],
    };
    const permissionId = dataPermission.map((val) => {
      return val.id;
    });
    payload.permissionIds = permissionId;
    try {
      const response = await assignRolePermissionDefault(payload);
      if (response) {
        notificationSuccess("Update Permission Berhasil", `Anda telah merubah akses role permission ini`);
      }
    } catch (error) {
      console.log(error);
      notificationError("Update Permission Gagal", `Perubahan role permission ini gagal di update`);
    } finally {
      setLoadingSubmit(false);
      onCloseModal();
    }
  };

  const loadingPermission = (number) =>
    Array.from({ length: number }, (_, i) => {
      return (
        <Box key={i}>
          <Skeleton height={40} width="100%" radius="md" mb="md" />
        </Box>
      );
    });

  const selectPermission = (eventChecked, valuePermission) => {
    const data = selectedPermissionRoleId;
    if (eventChecked) {
      const dataSelected = {
        id: valuePermission.id,
        name: valuePermission.name,
        alias: valuePermission.alias,
      };
      data.push(dataSelected);
      setSelectedPermissionRoleId(selectedPermissionRoleId);
    } else {
      for (let i = 0; i < selectedPermissionRoleId.length; i++) {
        if (selectedPermissionRoleId[i].id === valuePermission.id) {
          data.splice(i, 1);
        }
      }
      setSelectedPermissionRoleId([...selectedPermissionRoleId]);
    }
  };

  const mappingPermissionState = (statePermission) => {
    const mappingDataPermission = statePermission?.map((val, index) => {
      return (
        <Flex justify="space-between" align="center" mb={12} key={index}>
          <Text fz={13} fw="400">
            {val.name}
          </Text>
          <Switch onLabel="ON" offLabel="OFF" checked={selectedPermissionRoleId.find((value) => value.id === val.id)} onChange={(e) => selectPermission(e.currentTarget.checked, val)} />
        </Flex>
      );
    });
    return mappingDataPermission;
  };

  const mappingPermissionRole = (dataList) =>
    Object.keys(dataList).map((val, index) => {
      return (
        <Accordion.Item key={index} value={val}>
          <Accordion.Control>
            <Text fz="sm" fw="600" tt="capitalize">
              {val}
            </Text>
          </Accordion.Control>
          <Accordion.Panel>{mappingPermissionState(Object.values(dataList)[index])}</Accordion.Panel>
        </Accordion.Item>
      );
    });

  return (
    <Box>
      <ScrollArea my={16} h={500} offsetScrollbars scrollbarSize={6}>
        {loading ? loadingPermission(8) : <Accordion variant="separated">{mappingPermissionRole(permissionList)}</Accordion>}
      </ScrollArea>
      <Box mt={20}>
        <Flex justify="flex-end">
          <Group>
            <Button variant="outline" color="indigo.9" onClick={onCloseModal}>
              Tutup
            </Button>
            <Button loading={loadingSubmit} variant="filled" color="indigo.9" onClick={() => submitPermission(selectedPermissionRoleId)}>
              Simpan
            </Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  );
};

export default FormRolePermission;
