import { createSlice } from "@reduxjs/toolkit";

const authData = createSlice({
  name: "auth",
  initialState: {
    dataUser: null,
    isLogin: false,
    permission: null,
  },
  reducers: {
    update: (state, action) => {
      state.dataUser = action.payload.dataUser;
      state.isLogin = action.payload.isLogin;
    },
  },
});

export const { update } = authData.actions;
export default authData.reducer;
