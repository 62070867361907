import React from "react";
import { Table, Badge, Text } from "@mantine/core";
import { formatedTime, parseDateLocal } from "../../../plugins/helper";

const TableHistoryUserLogin = ({ data }) => {
  const mappingDataTable = (data) =>
    data.map((val, index) => {
      return (
        <Table.Tr key={index}>
          {/* <Table.Td>{index + countData + 1}.</Table.Td> */}
          <Table.Td>
            <Text fz={14}>{val.application !== null ? val.application.name.toUpperCase() : "-"}</Text>
            <Text fz={12}>IP: {val.application !== null ? val.ip : "-"}</Text>
          </Table.Td>
          <Table.Td>
            <Badge size="md" radius="sm" variant="filled" color={val.status === "login" ? "green" : "red"}>
              {val.status || "-"}
            </Badge>
          </Table.Td>
          <Table.Td>
            <Text fz={12}>
              {parseDateLocal(val.createdAt)}, {formatedTime(val.createdAt)}
            </Text>
          </Table.Td>
        </Table.Tr>
      );
    });

  return (
    <Table.ScrollContainer minWidth={400}>
      <Table horizontalSpacing="sm" withTableBorder style={{ fontSize: "13px" }}>
        <Table.Tbody>{mappingDataTable(data)}</Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
};

export default TableHistoryUserLogin;
