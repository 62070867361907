import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Group, Loader, MultiSelect, Switch, Text, TextInput } from "@mantine/core";
import { getListApplication } from "../../../services/application";
import { addOrganization, checkNicknameOrganization, updateOrganization } from "../../../services/organization";
import { notificationError, notificationSuccess } from "../../ui/Notification";
import { validation } from "../../../plugins/validation";
import FormSkeleton from "../../ui/FormSkeleton";
import { IconCheck } from "@tabler/icons-react";

const defaultVal = {
  name: "",
  nickName: "",
  email: "",
  phone: "",
  isActive: false,
  applicationId: [],
};

const formValidation = {
  name: {
    isError: false,
    message: "",
  },
  nickName: {
    isError: false,
    message: "",
  },
  email: {
    isError: false,
    message: "",
  },
  phone: {
    isError: false,
    message: "",
  },
  isActive: {
    isError: false,
    message: "",
  },
  applicationId: {
    isError: false,
    message: "",
  },
};

const FormOrganization = ({ dataOrganization, onCloseModal, reloadList }) => {
  const [loadingForm, setLoadingForm] = useState(false);
  const [formData, setFormData] = useState(defaultVal);
  const [validationForm, setValidationForm] = useState(formValidation);
  const [selectedApplicationId, setSelectedApplicationId] = useState([]);
  const [applicationList, setApplicationList] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [isUnique, setIsUnique] = useState(null);
  const [inputTimeout, setInputTimeout] = useState(null);

  const handleGetListApplication = async () => {
    setLoadingForm(true);
    try {
      const response = await getListApplication();
      const dataApplication = response.data;
      const mappingApplication = dataApplication.map((val) => {
        return {
          value: val?.id?.toString(),
          label: val?.name,
        };
      });
      setApplicationList(mappingApplication);
    } catch (error) {
      console.log(error);
    }
    setLoadingForm(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSwitch = () => {
    setFormData({ ...formData, isActive: !formData.isActive });
  };

  useEffect(() => {
    checkNicknameOrganization();
  }, []);

  useEffect(() => {
    handleGetListApplication();
    if (dataOrganization !== null) {
      handleSetForm(dataOrganization);
    }
    // eslint-disable-next-line
  }, [dataOrganization]);

  const validateNickname = (e) => {
    // const key = e.target.name;
    const value = e.target.value;
    const pattern = /^[a-zA-Z0-9\s]+$/;
    const rules = pattern.test(value);
    setIsValid(rules);
    setIsUnique(value);

    setFormData((old) => ({
      ...old,
      nickName: value,
    }));
    const formNickname = { nickName: value };
    checkNicknameOrganization(formNickname)
      .then(function (response) {
        // console.log(response.message);
        if (inputTimeout) clearTimeout(inputTimeout);
        setInputTimeout(
          setTimeout(() => {
            setIsUnique(response.status);
          }, 0)
        );
        if (response.status === false) {
          setFormData((old) => ({
            ...old,
            nickName: value,
          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSetForm = (dataOrganization) => {
    const dataDetail = {
      name: dataOrganization?.name,
      nickName: dataOrganization?.nickName,
      email: dataOrganization?.mainEmail,
      phone: dataOrganization?.mainPhone,
      isActive: Boolean(dataOrganization?.isActive),
      applicationId: selectedApplicationId,
    };
    setSelectedApplicationId(dataOrganization?.applicationOrganization?.map((el) => el?.application?.id));
    setFormData(dataDetail);
  };

  const handleSubmit = async (data) => {
    let methodFunction = null;
    let titleMessageSuccess = "";
    let captionMessageSuccess = "";
    let titleMessageError = "";
    let captionMessageError = "";
    setLoadingForm(true);
    setValidationForm(formValidation);
    const payload = {
      name: data.name,
      nickName: data.nickName,
      email: data.email,
      phone: data.phone,
      isActive: Boolean(data.isActive),
      applicationId: selectedApplicationId,
    };
    const isError = validation(payload, setValidationForm);
    if (isError) {
      setLoadingForm(false);
      return;
    }
    if (dataOrganization !== null) {
      methodFunction = updateOrganization(dataOrganization?.id, payload);
      titleMessageSuccess = "Update Organisasi Berhasil";
      captionMessageSuccess = "Anda telah berhasil mengupdate data Organisasi";
      titleMessageError = "Gagal Mengupdate Organisasi";
      captionMessageError = "Silahkan cek kembali form anda";
    } else {
      methodFunction = addOrganization(payload);
      titleMessageSuccess = "Tambah Organisasi Berhasil";
      captionMessageSuccess = "Anda telah berhasil menambahkan Organisasi baru";
      titleMessageError = "Gagal Menambahkan Organisasi";
      captionMessageError = "Silahkan cek kembali form anda";
    }
    try {
      const response = await methodFunction;
      if (response) {
        onCloseModal();
        reloadList();
        notificationSuccess(titleMessageSuccess, captionMessageSuccess);
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      notificationError(titleMessageError, `${Object.keys(errorMessage) ? errorMessage : captionMessageError}`);
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el,
              },
            }));
          }
        });
      });
    } finally {
      setLoadingForm(false);
    }
  };

  return (
    <Box>
      {!loadingForm ? (
        <>
          <Box mb="md">
            <TextInput name="name" value={formData.name} label="Nama Organisasi" placeholder="Masukkan nama organisasi" error={validationForm.name.isError ? `${validationForm.name.message}` : ""} onChange={handleChange} withAsterisk />
          </Box>
          <Box mb="md">
            <TextInput
              name="nickName"
              value={formData.nickName}
              label="Nickname Organisasi"
              placeholder="Masukkan nickname organisasi"
              error={validationForm.nickName.isError ? `${validationForm.nickName.message}` : ""}
              onChange={validateNickname}
              withAsterisk
            />
            {isUnique === false ? (
              <Text size="sm" sx={{ marginBottom: 0 }} c="red">
                Nickname sudah ada!
              </Text>
            ) : isUnique === true && isValid === true ? (
              <>
                <Box display="flex">
                  <Text size="sm" sx={{ marginBottom: 0, marginTop: "8px" }} c="teal">
                    Nickname tersedia
                  </Text>
                  <Text size="xs" c="teal">
                    &nbsp;
                    <IconCheck fontSize="sm" c="teal" />
                  </Text>
                </Box>
              </>
            ) : inputTimeout !== null && isValid === true ? (
              <Text size="sm" c="blue">
                Memeriksa keunikan nickname <Loader color="blue" size="xs" />
              </Text>
            ) : (
              <Text size="sm" sx={{ marginBottom: 0 }} c="red"></Text>
            )}
          </Box>
          <Box mb="md">
            <TextInput
              disabled={isUnique === true && isValid === true ? false : true}
              name="email"
              value={formData.email}
              label="Email"
              placeholder="Masukkan nama organisasi"
              error={validationForm.email.isError ? `${validationForm.email.message}` : ""}
              onChange={handleChange}
              withAsterisk
            />
          </Box>
          <Box mb="md">
            <TextInput
              disabled={isUnique === true && isValid === true ? false : true}
              name="phone"
              value={formData.phone}
              label="No. Telepon"
              placeholder="Masukkan nomor telepon"
              error={validationForm.phone.isError ? `${validationForm.phone.message}` : ""}
              onChange={handleChange}
              withAsterisk
            />
          </Box>
          <Box mb="md">
            <MultiSelect
              name="applicationId"
              label="Aplikasi"
              placeholder="Pilih satu/lebih aplikasi"
              searchable
              nothingFoundMessage="Aplikasi tidak ditemukan"
              data={applicationList}
              onChange={(e) => setSelectedApplicationId(e)}
              error={validationForm?.applicationId?.isError ? `${validationForm?.applicationId?.message}` : ""}
              value={selectedApplicationId}
              withAsterisk
            />
          </Box>
          <Box mb="md">
            <Flex justify="flex-start">
              <Text fz={14} mx={3} mr={8}>
                Active :{" "}
              </Text>
              <Text fz={14} mx={3} mr={8} c="red">
                {validationForm?.isActive?.isError ? `${validationForm?.isActive?.message}` : ""}
              </Text>
              <Switch onLabel="YES" offLabel="NO" checked={formData.isActive} onChange={handleSwitch} />
            </Flex>
          </Box>
        </>
      ) : (
        <Box>
          <FormSkeleton total={6} />
        </Box>
      )}

      <Box mt={20}>
        <Flex justify="flex-end">
          <Group>
            <Button variant="outline" color="indigo.9" onClick={onCloseModal}>
              Tutup
            </Button>
            <Button loading={loadingForm} variant="filled" color="indigo.9" onClick={() => handleSubmit(formData)}>
              Simpan
            </Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  );
};

export default FormOrganization;
