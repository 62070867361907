import React, { useEffect, useState } from "react";
import classes from "./userOrg.module.css";
import Sidebar from "../../components/ui/AppShell/Sidebar";
import { Box, Flex, Modal, Pagination, ScrollArea, Text, TextInput, rem } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import { useMediaQuery } from "@mantine/hooks";
import { getListUserOrg } from "../../services/usersOrg";
import FormUserOrg from "../../components/pages/userOrg/FormUserOrg";
import TableSkeleton from "../../components/ui/TableSkeleton";
import TableUsersOrg from "../../components/pages/userOrg/TableUserOrg";

const defaultParameter = {
  skip: 0,
  take: 10,
  keyword: "",
  orderBy: "fullName",
  order: "ASC",
};

const labelTable = [
  {
    label: "No.",
    width: 30,
  },
  {
    label: "Nama",
    width: "auto",
  },
  {
    label: "Email",
    width: "auto",
  },
  {
    label: "Role",
    width: "auto",
  },
  {
    label: "Status",
    width: "auto",
  },
  {
    label: "Action",
    width: 100,
  },
];

const UserOrg = () => {
  const [params, setParams] = useState(defaultParameter);
  const [userOrgList, setUserOrgList] = useState([]);
  const [count, setCount] = useState(0);
  const [detailData, setDetailData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openModalForm, setOpenModalForm] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 425px)");

  const handleGetUserOrgList = async () => {
    setLoading(true);
    try {
      const response = await getListUserOrg(params);
      setUserOrgList(response.data);
      setCount(response.count);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetUserOrgList();
    // eslint-disable-next-line
  }, [params]);

  const handleSearchChange = (e) => {
    const params = {
      skip: 0,
      take: 10,
      keyword: e.target.value,
      orderBy: "fullName",
      order: "asc",
    };
    setParams(params);
  };

  const handleChangePage = (e) => {
    const params = {
      skip: (e - 1) * 10,
      take: 10,
      keyword: "",
      orderBy: "fullName",
      order: "asc",
    };
    setParams(params);
  };

  const onCloseModalForm = () => {
    setOpenModalForm(false);
    setDetailData(null);
  };

  const actions = {
    edit: (val) => {
      setOpenModalForm(true);
      setDetailData(val);
    },
  };

  const handleAction = (val, type) => {
    return actions[type](val);
  };

  return (
    <Sidebar>
      <Box>
        <Text className={classes.titlePage} mb={20}>
          Users Management
        </Text>

        <Box style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
          <Flex>
            <TextInput leftSectionPointerEvents="none" leftSection=<IconSearch style={{ width: rem(16), height: rem(16) }} /> placeholder="Cari User. . ." name="search" onChange={handleSearchChange} />
          </Flex>
        </Box>
        <Box my={20}>{loading ? <TableSkeleton total={5} /> : <TableUsersOrg label={labelTable} data={userOrgList} countData={params.skip} actionMethod={handleAction} />}</Box>
        <Flex justify="end">
          <Pagination onChange={handleChangePage} total={Math.ceil(count / params.take) || 0} color="#0572b9" radius="lg" />
        </Flex>
        <Modal
          opened={openModalForm}
          onClose={onCloseModalForm}
          centered
          closeOnClickOutside={false}
          size="lg"
          title={<Text fw="Bold">{detailData ? "Edit User" : "Tambah User"}</Text>}
          scrollAreaComponent={ScrollArea.Autosize}
          withCloseButton={false}
        >
          <FormUserOrg dataUserOrg={detailData} onCloseModal={onCloseModalForm} reloadList={handleGetUserOrgList} />
        </Modal>
      </Box>
    </Sidebar>
  );
};

export default UserOrg;
