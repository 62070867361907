import { Box, Button, Flex, Group, Text } from "@mantine/core";
import React from "react";
import { logout } from "../../../services/auth";
import Cookies from "universal-cookie";

const FormLogout = ({ onCloseModal }) => {
  const cookies = new Cookies();

  const handleLogout = async () => {
    const domain = window.location.hostname;

    await logout().then((res) => {
      console.log(res);
      cookies.remove("authData", { path: "/", domain: "tandeem.co.id" });
      cookies.remove("token_account_tandeem", { path: "/", domain: "tandeem.co.id" });
      cookies.remove("refreshToken_account_tandeem", { path: "/", domain: "tandeem.co.id" });
      setTimeout(() => {
        cookies.remove("authData", { path: "/", domain: "tandeem.co.id" });
        cookies.remove("token_account_tandeem", { path: "/", domain: "tandeem.co.id" });
        cookies.remove("refreshToken_account_tandeem", { path: "/", domain: "tandeem.co.id" });
        domain === "localhost" ? (window.location.href = `http://localhost:3001`) : (window.location.href = `https://hris.tandeem.co.id`);
      }, 400);
    });
  };

  return (
    <Box>
      <Box mb="md">
        <Text fz={14} mx={3} mr={8}>
          Apakah Anda yakin ingin keluar dari akun ini?
        </Text>{" "}
      </Box>
      <Box mt={20}>
        <Flex justify="flex-end">
          <Group>
            <Button variant="outline" color="indigo.9" onClick={onCloseModal}>
              Tidak
            </Button>
            <Button onClick={handleLogout} variant="filled" color="indigo.9">
              Ya
            </Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  );
};

export default FormLogout;
