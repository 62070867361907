import React, { useEffect, useState } from "react";
import classes from "./accountLogin.module.css";
import { useMediaQuery } from "@mantine/hooks";
import { ActionIcon, Box, Card, Divider, Group, Modal, ScrollArea, Skeleton, Text, rem } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { getListApplicationUser } from "../../services/application";
import { update } from "../../store/authData";
import Cookies from "universal-cookie";
import http from "../../plugins/axios";
// import CryptoJS from "crypto-js";
import { getListHistoryWebUser } from "../../services/historyWeb";
import TableSkeleton from "../../components/ui/TableSkeleton";
import TableHistoryUserLogin from "../../components/pages/accountLogin/TableHistoryUserLogin";
import { IconEdit, IconLogout } from "@tabler/icons-react";
import FormLogout from "../../components/pages/accountLogin/FormLogout";
import FormEditProfile from "../../components/pages/accountLogin/FormEditProfile";
import { getUserAccount } from "../../services/users";

const defaultParameter = {
  orderBy: "name",
  order: "ASC",
  take: 10,
  skip: 0,
};

const defaultParameterHistory = {
  orderBy: "createdAt",
  order: "DESC",
  take: 10,
  skip: 0,
  keywords: "",
};

const LoggedIn = ({ appId }) => {
  const cookies = new Cookies();
  const isMobile = useMediaQuery("(max-width: 430px)");
  // const cryptoKey = "30125c4f90b4b958";
  const param = defaultParameter;
  const paramHIstory = defaultParameterHistory;
  const dispatch = useDispatch();
  const dataUser = useSelector((state) => state.auth.dataUser);
  const [appUserList, setAppUserList] = useState([]);
  const [historyUserList, setHistoryUserList] = useState([]);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalLogout, setOpenModalLogout] = useState(false);
  const hrisId = "468a907d-b6c0-4dc8-881a-f24cd3e8d83d";

  console.log(appUserList);
  // console.log(dataUser);

  const handleGetAppUserList = async () => {
    setLoading(true);
    const authDataHris = cookies.get("authData");
    const token = cookies.get("token_account_tandeem");

    if (token) {
      // const bytes = CryptoJS.AES.decrypt(token, cryptoKey);
      // const decrypted = bytes.toString(CryptoJS.enc.Utf8);
      // const dataLoggedIn = JSON.parse(decrypted);
      http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      dispatch(update({ dataUser: { ...authDataHris, token }, isLogin: true }));
      setProfile(authDataHris);
      try {
        const response = await getListApplicationUser(param);
        setAppUserList(response.data);
      } catch (error) {
        console.log("Failed to fetch application user list:", error);
      }
      setLoading(false);
    }
  };

  const handleGetHIstoryUserList = async () => {
    setLoading(true);
    // const authDataHris = cookies.get("authData");
    const token = cookies.get("token_account_tandeem");

    if (token) {
      try {
        const response = await getListHistoryWebUser(paramHIstory);
        setHistoryUserList(response.data);
      } catch (error) {
        console.log("Failed to fetch application user list:", error);
      }
      setLoading(false);
    }
  };

  const handleGetUserAccount = async () => {
    setLoading(true);
    const token = cookies.get("token_account_tandeem");

    if (token) {
      try {
        const response = await getUserAccount({ applicationId: appId });
        setProfile(response);
      } catch (error) {
        console.log("Failed to fetch user account:", error);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetAppUserList();
    handleGetHIstoryUserList();
    handleGetUserAccount();
    //eslint-disable-next-line
  }, []);

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const onCloseModalLogout = () => {
    setOpenModalLogout(false);
  };

  return (
    <Box className={classes.card}>
      <Box className={classes.flexBox}>
        <Card mt={20} shadow="lg" padding="lg" radius="lg" w={isMobile ? 280 : 600}>
          <Text fw={700} fz={18} mb="sm">
            {dataUser?.organization}
          </Text>
          <Box className={classes.flexbetween}>
            <Box>
              <Text fw={400}>{profile?.fullName}</Text>
              <Text fw={400}>{profile?.email}</Text>
              <Text fw={400}>Role: {profile?.role.name}</Text>
            </Box>
            <Box>
              <ActionIcon.Group orientation="vertical">
                <ActionIcon variant="default" size="lg" aria-label="edit" onClick={() => setOpenModal(true)}>
                  <IconEdit style={{ width: rem(20) }} stroke={1.5} />
                </ActionIcon>

                <ActionIcon variant="default" size="lg" aria-label="logout" onClick={() => setOpenModalLogout(true)}>
                  <IconLogout style={{ width: rem(20) }} stroke={1.5} />
                </ActionIcon>
              </ActionIcon.Group>
            </Box>
          </Box>

          <Divider my={30} />
          <Text fw={700} fz={16}>
            Your App
          </Text>
          {!loading ? (
            <>
              {appUserList.map((el) => {
                return (
                  <Box my={10} p={20} className={classes.boxApp} key={el?.name} onClick={() => (window.location.href = el?.id !== hrisId ? `https://${el.url}` : `https://hris.tandeem.co.id`)}>
                    <Group justify="space-between">
                      <Text fw={700}> {el?.name.toUpperCase()}</Text>
                      <Text fw={400}> {el?.url}</Text>
                    </Group>
                  </Box>
                );
              })}
            </>
          ) : (
            <Box>
              <Skeleton my={10} height={70} width="100%" radius="md" mb="md" />
              <Skeleton my={10} height={70} width="100%" radius="md" mb="md" />
            </Box>
          )}
        </Card>

        <Card mt={20} shadow="lg" padding="lg" radius="lg" w={isMobile ? 280 : 600}>
          <Box>
            <Text fw={700} fz={16}>
              History Login
            </Text>
            <Box my={10}>{loading ? <TableSkeleton total={5} /> : <TableHistoryUserLogin data={historyUserList} />}</Box>
          </Box>
        </Card>
      </Box>

      <Modal opened={openModal} onClose={onCloseModal} centered closeOnClickOutside={true} size="lg" title={<Text fw="Bold">Edit Profile</Text>} scrollAreaComponent={ScrollArea.Autosize} withCloseButton={false}>
        <FormEditProfile onCloseModal={onCloseModal} profile={profile} reload={handleGetUserAccount} />
      </Modal>
      <Modal opened={openModalLogout} onClose={onCloseModalLogout} centered closeOnClickOutside={false} size="lg" title={<Text fw="Bold">Loguot</Text>} scrollAreaComponent={ScrollArea.Autosize} withCloseButton={false}>
        <FormLogout onCloseModal={onCloseModalLogout} />
      </Modal>
    </Box>
  );
};

export default LoggedIn;
