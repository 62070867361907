import http from "../plugins/axios";
import { handleError } from "../plugins/helper";

export const getListApplication = async (params) => {
  try {
    const response = await http.get(`/application`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDetailApplication = async (id) => {
  try {
    const response = await http.get(`/application/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getListApplicationUser = async (params) => {
  try {
    const response = await http.get(`/application/user`, { params });
    return response.data;
  } catch (error) {
    handleError(error.response.data.statusCode, error.response.data.message);
    throw error;
  }
};

export const addApplication = async (payload) => {
  try {
    const response = await http.post(`/application`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateApplication = async (id, payload) => {
  try {
    const response = await http.patch(`/application/update/${id}`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteApplication = async (id) => {
  try {
    const response = await http.delete(`/application/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
