import React from "react";
import classes from "./header.module.css";
import { useSelector } from "react-redux";
// import { useMediaQuery } from "@mantine/hooks";
import { Box, Burger, Flex, Group, Text, Avatar, Image } from "@mantine/core";
import Logo from "../../../../assets/img/logo tandeem.png";

const Header = ({ mobileToggle, desktopToggle }) => {
  const dataUser = useSelector((state) => state.auth.dataUser);
  // console.log(dataUser);
  // const isMobile = useMediaQuery("(max-width: 425px)");

  return (
    <header className={classes.root}>
      <Box className={classes.sectionHeader}>
        <Group gap="xs" align="center">
          <Burger onClick={mobileToggle} hiddenFrom="sm" size="sm" />
          <Burger onClick={desktopToggle} visibleFrom="sm" size={20} />
          <Image radius="xs" src={null} h={20} p={1.5} fallbackSrc={Logo} display={{ base: "none", md: "block" }} />
          <Text fw={700}>SSO Admin</Text>
        </Group>
        <Box>
          <Flex align="center">
            <Avatar variant="light" src={null} radius="xl" size={45} color="indigo.9" />
            <Text c="indigo.9" fw="bold" fz={13} ml="sm">
              {dataUser?.fullName}
            </Text>
            {/* {!isMobile && (
              <Box ml={8}>
                <Text c="indigo.9" fw="bold" fz={13}>
                  {dataUser?.fullName}
                </Text>
                {dataUser?.role?.map((el) => (
                  <Text c="indigo.9" fz={12}>
                    {el?.name}, {el?.applicationRole?.[0]?.application?.name}
                  </Text>
                ))}
              </Box>
            )} */}
          </Flex>
        </Box>
      </Box>
    </header>
  );
};

export default Header;
