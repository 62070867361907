import { Box, Button, Flex, Group, Select, Switch, Text, TextInput } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { getListApplication } from "../../../services/application";
import FormSkeleton from "../../ui/FormSkeleton";
import { validation } from "../../../plugins/validation";
import { notificationError, notificationSuccess } from "../../ui/Notification";
import { addAppApiKey, updateAppApiKey } from "../../../services/appApiKey";

const defaultVal = {
  name: "",
  isActive: false,
  applicationId: [],
};

const formValidation = {
  name: {
    isError: false,
    message: "",
  },
  isActive: {
    isError: false,
    message: "",
  },
  applicationId: {
    isError: false,
    message: "",
  },
};

const FormAppApi = ({ onCloseModal, dataAppApi, reloadList }) => {
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [formData, setFormData] = useState(defaultVal);
  const [validationForm, setValidationForm] = useState(formValidation);
  const [selectedApplicationId, setSelectedApplicationId] = useState([]);
  const [applicationList, setApplicationList] = useState([]);
  console.log(formData);
  console.log(selectedApplicationId);
  console.log(applicationList);

  const handleGetListApplication = async () => {
    setLoadingForm(true);
    try {
      const response = await getListApplication();
      const dataApplication = response.data;
      const mappingApplication = [
        { value: "", label: "Select aplikasi" },
        ...dataApplication.map((val) => ({
          value: val?.id?.toString(),
          label: val?.name,
        })),
      ];
      setApplicationList(mappingApplication);
    } catch (error) {
      console.log(error);
    }
    setLoadingForm(false);
  };

  useEffect(() => {
    handleGetListApplication();
    // eslint-disable-next-line
  }, [selectedApplicationId]);

  useEffect(() => {
    handleGetListApplication();
    if (dataAppApi !== null) {
      handleSetForm(dataAppApi);
    }
    // eslint-disable-next-line
  }, [dataAppApi]);

  const handleSubmit = async (data) => {
    let methodFunction = null;
    let titleMessageSuccess = "";
    let captionMessageSuccess = "";
    let titleMessageError = "";
    let captionMessageError = "";
    setLoading(true);
    setValidationForm(formValidation);
    const payload = {
      name: data.name,
      isActive: Boolean(data.isActive),
      applicationId: selectedApplicationId,
    };
    const isError = validation(payload, setValidationForm);
    if (isError) {
      setLoading(false);
      return;
    }
    if (dataAppApi !== null) {
      methodFunction = updateAppApiKey(dataAppApi?.id, payload);
      titleMessageSuccess = "Update API Key Berhasil";
      captionMessageSuccess = "Anda telah berhasil mengupdate data API Key";
      titleMessageError = "Gagal Mengupdate API Key";
      captionMessageError = "Silahkan cek kembali form anda";
    } else {
      methodFunction = addAppApiKey(payload);
      titleMessageSuccess = "Tambah API Key Berhasil";
      captionMessageSuccess = "Anda telah berhasil menambahkan API Key baru";
      titleMessageError = "Gagal Menambahkan API Key";
      captionMessageError = "Silahkan cek kembali form anda";
    }
    try {
      const response = await methodFunction;
      if (response) {
        onCloseModal();
        reloadList();
        notificationSuccess(titleMessageSuccess, captionMessageSuccess);
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      notificationError(titleMessageError, `${Object.keys(errorMessage) ? errorMessage : captionMessageError}`);
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el,
              },
            }));
          }
        });
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSetForm = (dataAppApi) => {
    const dataDetail = {
      name: dataAppApi?.name,
      isActive: dataAppApi?.isActive,
      applicationId: selectedApplicationId,
    };
    setSelectedApplicationId(dataAppApi?.application?.id);
    setFormData(dataDetail);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSwitch = () => {
    setFormData({ ...formData, isActive: !formData.isActive });
  };

  return (
    <Box>
      {!loadingForm ? (
        <>
          {" "}
          <Box mb="md">
            <TextInput name="name" value={formData.name} label="Name" placeholder="Masukkan nama API" error={validationForm.name.isError ? `${validationForm.name.message}` : ""} onChange={handleChange} withAsterisk />
          </Box>
          <Box mb="md">
            <Select
              name="applicationId"
              label="Aplikasi"
              placeholder="Pilih aplikasi"
              searchable
              nothingFoundMessage="Aplikasi tidak ditemukan"
              data={applicationList}
              onChange={(e) => setSelectedApplicationId(e)}
              error={validationForm?.applicationId?.isError ? `${validationForm?.applicationId?.message}` : ""}
              value={selectedApplicationId}
              withAsterisk
            />
          </Box>
          <Box mb="md">
            <Flex justify="flex-start">
              <Text fz={14} mx={3} mr={8}>
                Active :{" "}
              </Text>
              <Text fz={14} mx={3} mr={8} c="red">
                {validationForm?.isActive?.isError ? `${validationForm?.isActive?.message}` : ""}
              </Text>
              <Switch onLabel="YES" offLabel="NO" checked={formData.isActive} onChange={handleSwitch} />
            </Flex>
          </Box>
        </>
      ) : (
        <Box>
          <FormSkeleton total={3} />
        </Box>
      )}
      <Box mt={20}>
        <Flex justify="flex-end">
          <Group>
            <Button variant="outline" color="indigo.9" onClick={onCloseModal}>
              Tutup
            </Button>
            <Button loading={loading} variant="filled" color="indigo.9" onClick={() => handleSubmit(formData)}>
              Simpan
            </Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  );
};

export default FormAppApi;
