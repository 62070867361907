import React, { useEffect, useState } from "react";
import classes from "./application.module.css";
import Sidebar from "../../components/ui/AppShell/Sidebar";
import { Box, Button, Flex, Modal, Pagination, ScrollArea, Text, TextInput, rem } from "@mantine/core";
import { IconCopyPlus, IconSearch } from "@tabler/icons-react";
import { useMediaQuery } from "@mantine/hooks";
import TableSkeleton from "../../components/ui/TableSkeleton";
import TableApplication from "../../components/pages/application/TableApplication";
import { getListApplication } from "../../services/application";
import FormApplication from "../../components/pages/application/FormApplication";

const defaultParameter = {
  skip: 0,
  take: 10,
  keyword: "",
  orderBy: "name",
  order: "ASC",
};

const labelTable = [
  {
    label: "No.",
    width: 30,
  },
  {
    label: "Nama",
    width: "auto",
  },
  {
    label: "URL",
    width: "auto",
  },
  {
    label: "Created At",
    width: "auto",
  },
  {
    label: "Status",
    width: "auto",
  },
  {
    label: "Action",
    width: 100,
  },
];

const Application = () => {
  const [params, setParams] = useState(defaultParameter);
  const [applicationList, setApplicationList] = useState([]);
  const [count, setCount] = useState(0);
  const [detailData, setDetailData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openModalForm, setOpenModalForm] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 425px)");

  const handleGetApplicationList = async () => {
    setLoading(true);
    try {
      const response = await getListApplication(params);
      setApplicationList(response.data);
      setCount(response.count);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetApplicationList();
    // eslint-disable-next-line
  }, [params]);

  const handleSearchChange = (e) => {
    const params = {
      skip: 0,
      take: 10,
      keyword: e.target.value,
      orderBy: "name",
      order: "ASC",
    };
    setParams(params);
  };

  const handleChangePage = (e) => {
    const params = {
      skip: (e - 1) * 10,
      take: 10,
      keyword: e.target.value,
      orderBy: "name",
      order: "ASC",
    };
    setParams(params);
  };

  const onCloseModalForm = () => {
    setOpenModalForm(false);
    setDetailData(null);
  };

  const actions = {
    edit: (val) => {
      setOpenModalForm(true);
      setDetailData(val);
    },
  };

  const handleAction = (val, type) => {
    return actions[type](val);
  };

  return (
    <Sidebar>
      <Box>
        <Text className={classes.titlePage} mb={20}>
          Applications Management
        </Text>

        <Box style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
          <Flex>
            <TextInput leftSectionPointerEvents="none" leftSection=<IconSearch style={{ width: rem(16), height: rem(16) }} /> placeholder="Cari Aplikasi. . ." name="search" onChange={handleSearchChange} />
          </Flex>
          <Flex mt={isSmallScreen ? 10 : 0}>
            <Button fullWidth={isSmallScreen} leftSection={<IconCopyPlus size={14} />} color="#0572b9" onClick={() => setOpenModalForm(true)}>
              Tambah Aplikasi
            </Button>
          </Flex>
        </Box>
        <Box my={20}>{loading ? <TableSkeleton total={5} /> : <TableApplication label={labelTable} data={applicationList} countData={params.skip} actionMethod={handleAction} />}</Box>
        <Flex justify="end">
          <Pagination onChange={handleChangePage} total={Math.ceil(count / params.take) || 0} color="#0572b9" radius="lg" />
        </Flex>
      </Box>

      <Modal
        opened={openModalForm}
        // onClose={onCloseModalForm}
        centered
        closeOnClickOutside={false}
        size="lg"
        title={<Text fw="Bold">{detailData ? "Edit Aplikasi" : "Tambah Aplikasi"}</Text>}
        scrollAreaComponent={ScrollArea.Autosize}
        withCloseButton={false}
      >
        <FormApplication dataApplication={detailData} onCloseModal={onCloseModalForm} reloadList={handleGetApplicationList} />
      </Modal>
    </Sidebar>
  );
};

export default Application;
