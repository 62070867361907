import { Box, Button, Checkbox, Flex, Group, MultiSelect, PasswordInput, Popover, Progress, Switch, Text, TextInput } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { getListRoleOrg } from "../../../services/roleOrg";
import FormSkeleton from "../../ui/FormSkeleton";
import { validation } from "../../../plugins/validation";
import { addUser, updateUser } from "../../../services/users";
import { notificationError, notificationSuccess } from "../../ui/Notification";
import PasswordRequired from "../../ui/PasswordRequired";

const defaultVal = {
  fullName: "",
  email: "",
  password: "",
  verifyPassword: "",
  roleId: [],
  isActive: false,
};

const formValidation = {
  fullName: {
    isError: false,
    message: "",
  },
  email: {
    isError: false,
    message: "",
  },
  password: {
    isError: false,
    message: "",
  },
  verifyPassword: {
    isError: false,
    message: "",
  },
  roleId: {
    isError: false,
    message: "",
  },
  isActive: {
    isError: false,
    message: "",
  },
};

const requirements = [
  { re: /[0-9]/, label: "Password harus mencantumkan angka" },
  { re: /[a-z]/, label: "Password harus mencantumkan huruf kecil" },
  { re: /[A-Z]/, label: "Password harus mencantumkan huruf besar" },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Password harus mencantumkan simbol" },
];

function getStrength(password) {
  let multiplier = password?.length > 7 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}

const FormUser = ({ dataUser, onCloseModal, reloadList }) => {
  const [loadingForm, setLoadingForm] = useState(false);
  const [formData, setFormData] = useState(defaultVal);
  const [validationForm, setValidationForm] = useState(formValidation);
  const [selectedRoleOrgId, setSelectedRoleOrgId] = useState([]);
  const [roleOrgList, setRoleOrgList] = useState([]);
  const [userPassword, setUserPassword] = useState(false);
  const [popoverPassword, setPopoverPassword] = useState(false);
  const checkPassword = requirements.map((val, index) => <PasswordRequired key={index} label={val.label} meets={val.re.test(formData.password)} />);
  const strengthPassword = getStrength(formData?.password);

  const progressBar = Array(4)
    .fill(0)
    .map((_, index) => {
      return (
        <Progress
          styles={{ section: { transitionDuration: "0ms" } }}
          value={Object.values(formData?.password).length > 0 && index === 0 ? 100 : strengthPassword >= ((index + 1) / 4) * 100 ? 100 : 0}
          color={strengthPassword > 80 ? "teal" : strengthPassword > 50 ? "yellow" : "red"}
          key={index}
          size={4}
        />
      );
    });

  const handleGetListRoleOrg = async () => {
    setLoadingForm(true);
    try {
      const response = await getListRoleOrg();
      const dataRoleOrg = response.data;
      const mappingRoleOrg = dataRoleOrg.map((val) => {
        return {
          value: val?.id?.toString(),
          label: `${val?.applicationRole?.[0]?.application?.name}, ${val?.name}`,
        };
      });
      setRoleOrgList(mappingRoleOrg);
    } catch (error) {
      console.log(error);
    }
    setLoadingForm(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSwitch = () => {
    setFormData({ ...formData, isActive: !formData.isActive });
  };

  useEffect(() => {
    handleGetListRoleOrg();
    if (dataUser !== null) {
      handleSetForm(dataUser);
    }
    // eslint-disable-next-line
  }, [dataUser]);

  const handleSetForm = (dataUser) => {
    const dataDetail = {
      fullName: dataUser?.fullName,
      email: dataUser?.email,
      isActive: dataUser?.isActive,
      roleId: selectedRoleOrgId,
      password: formData?.password,
    };
    setSelectedRoleOrgId(dataUser?.userRole?.map((el) => el?.role?.id.toString()));
    setFormData(dataDetail);
  };

  const handleSubmit = async (data) => {
    let methodFunction = null;
    let titleMessageSuccess = "";
    let captionMessageSuccess = "";
    let titleMessageError = "";
    let captionMessageError = "";
    setLoadingForm(true);
    setValidationForm(formValidation);
    const payload = {
      fullName: data.fullName,
      email: data.email,
      isActive: Boolean(data.isActive),
      roleId: selectedRoleOrgId,
      // password: data.password,
    };
    if (userPassword === true) {
      payload.password = data.password;
    }
    if (!dataUser) {
      payload.password = data.password;
    }
    const isError = validation(payload, setValidationForm);
    if (isError) {
      setLoadingForm(false);
      return;
    }
    if (dataUser !== null) {
      methodFunction = updateUser(dataUser?.id, payload);
      titleMessageSuccess = "Update Role Berhasil";
      captionMessageSuccess = "Anda telah berhasil mengupdate data Role";
      titleMessageError = "Gagal Mengupdate Role";
      captionMessageError = "Silahkan cek kembali form anda";
    } else {
      methodFunction = addUser(payload);
      titleMessageSuccess = "Tambah Role Berhasil";
      captionMessageSuccess = "Anda telah berhasil menambahkan Role baru";
      titleMessageError = "Gagal Menambahkan Role";
      captionMessageError = "Silahkan cek kembali form anda";
    }
    try {
      const response = await methodFunction;
      if (response) {
        onCloseModal();
        reloadList();
        notificationSuccess(titleMessageSuccess, captionMessageSuccess);
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      notificationError(titleMessageError, `${Object.keys(errorMessage) ? errorMessage : captionMessageError}`);
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el,
              },
            }));
          }
        });
      });
    } finally {
      setLoadingForm(false);
    }
  };

  const FormPassword = () => {
    // if (setPassword) {
    return (
      <Box>
        <Box mb="md">
          <Popover opened={popoverPassword} position="bottom" width="target" transitionProps={{ transition: "pop" }}>
            <Popover.Target>
              <Box onFocusCapture={() => setPopoverPassword(true)} onBlurCapture={() => setPopoverPassword(false)}>
                <PasswordInput
                  name="password"
                  value={formData.password}
                  placeholder="Masukkan password"
                  label="Password"
                  error={validationForm.password.isError ? `${validationForm.password.message}` : ""}
                  onChange={handleChange}
                  withAsterisk
                />
              </Box>
            </Popover.Target>
            <Popover.Dropdown>
              <Group gap={5} grow mt="xs" mb="md">
                {progressBar}
              </Group>
              <PasswordRequired label="Password harus lebih dari 7 karakter" meets={formData.password.length > 7} />
              {checkPassword}
            </Popover.Dropdown>
          </Popover>
        </Box>
        <Box mb="md">
          <PasswordInput
            value={formData.verifyPassword}
            name="verifyPassword"
            placeholder="Ulangi password anda"
            label="Konfirmasi Password"
            error={validationForm.verifyPassword.isError ? validationForm.verifyPassword.message : ""}
            onChange={handleChange}
            withAsterisk
          />
        </Box>
      </Box>
    );
    // }
  };

  return (
    <Box>
      {!loadingForm ? (
        <>
          <Box mb="md">
            <TextInput name="fullName" value={formData.fullName} label="Nama" placeholder="Masukkan nama" error={validationForm.fullName.isError ? `${validationForm.fullName.message}` : ""} onChange={handleChange} withAsterisk />
          </Box>
          <Box mb="md">
            <TextInput name="email" value={formData.email} label="Email" placeholder="Masukkan email" error={validationForm.email.isError ? `${validationForm.email.message}` : ""} onChange={handleChange} withAsterisk />
          </Box>
          <Box mb="md">
            <MultiSelect
              name="roleId"
              label="Role"
              placeholder="Pilih satu/lebih role"
              searchable
              nothingFoundMessage="Role tidak ditemukan"
              data={roleOrgList}
              onChange={(e) => setSelectedRoleOrgId(e)}
              error={validationForm?.roleId?.isError ? `${validationForm?.roleId?.message}` : ""}
              value={selectedRoleOrgId}
              withAsterisk
            />
          </Box>
          {dataUser && (
            <Box mb="md">
              <Checkbox checked={userPassword} onChange={(e) => setUserPassword(e.currentTarget.checked)} label="Atur Password" />
            </Box>
          )}
          {(userPassword === true || !dataUser) && <>{FormPassword()}</>}
          <Box mb="md">
            <Flex justify="flex-start">
              <Text fz={14} mx={3} mr={8}>
                Active :{" "}
              </Text>
              <Switch onLabel="YES" offLabel="NO" checked={formData.isActive} onChange={handleSwitch} />
            </Flex>
          </Box>
        </>
      ) : (
        <Box>
          <FormSkeleton total={4} />
        </Box>
      )}

      <Box mt={20}>
        <Flex justify="flex-end">
          <Group>
            <Button variant="outline" color="indigo.9" onClick={onCloseModal}>
              Tutup
            </Button>
            <Button loading={loadingForm} variant="filled" color="indigo.9" onClick={() => handleSubmit(formData)}>
              Simpan
            </Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  );
};

export default FormUser;
