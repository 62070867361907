import React from "react";
import { Box, Center, Text } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";

const PasswordRequired = ({ meets, label }) => {
  return (
    <Text component="div" c={meets ? "teal" : "red"} mt={5} size="sm">
      <Center inline>
        {meets ? <IconCheck size="0.9rem" stroke={1.5} /> : <IconX size="0.9rem" stroke={1.5} />}
        <Box ml={7}>{label}</Box>
      </Center>
    </Text>
  );
};

export default PasswordRequired;
