import http from "../plugins/axios";

export const getListFeatureOrg = async (params) => {
  try {
    const response = await http.get(`/feature-organization`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getFeatureByApp = async (id) => {
  try {
    const response = await http.get(`/feature-organization/application/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const assignFeatureOrg = async (payload) => {
  try {
    const response = await http.post(`/feature-organization`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};
