import http from "../plugins/axios";

export const getListUserOrg = async (params) => {
  try {
    const response = await http.get(`/user-org`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDetailUserOrg = async (id) => {
  try {
    const response = await http.get(`/user-org/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateUserOrg = async (id, payload) => {
  try {
    const response = await http.patch(`/user-org/update/${id}`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};
