import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Group, Skeleton, Text } from "@mantine/core";
import { getListApplication } from "../../../services/application";
import FormFeatureOrg from "./FormFeatureOrg";
import { IconChevronRight } from "@tabler/icons-react";

const defaultParameter = {
  applicationId: "",
  organizationId: "",
};

const paramApp = {
  skip: 0,
  take: 10,
  keyword: "",
  orderBy: "name",
  order: "ASC",
};

const FormApp = ({ onCloseModal, dataOrganization }) => {
  const [params, setParams] = useState(defaultParameter);
  const [applicationList, setApplicationList] = useState([]);
  const [loadingForm, setLoadingForm] = useState(false);
  const paramsApp = paramApp;
  console.log("form app : ", params);

  const handleChooseApp = (el) => {
    setParams({ applicationId: el, organizationId: dataOrganization?.id });
  };

  const handleBackApp = () => {
    setParams({ applicationId: "", organizationId: dataOrganization?.id });
  };

  const handleGetListApplication = async () => {
    setLoadingForm(true);
    try {
      const response = await getListApplication(paramsApp);
      const dataApplication = response.data;
      const mappingApplication = dataApplication.map((val) => {
        return {
          value: val?.id?.toString(),
          label: val?.name,
        };
      });
      setApplicationList(mappingApplication);
    } catch (error) {
      console.log(error);
    }
    setLoadingForm(false);
  };

  useEffect(() => {
    handleGetListApplication();
    // eslint-disable-next-line
  }, [paramsApp]);

  return (
    <Box>
      {params.applicationId === "" ? (
        <Box mb="md">
          <Text fw={600} fz={16}>
            Pilih Aplikasi
          </Text>
          {!loadingForm ? (
            <>
              {applicationList?.map((el) => {
                return (
                  <Box my={10}>
                    <Button fullWidth radius="lg" justify="space-between" variant="outline" color="#0572b9" rightSection={<IconChevronRight size={14} />} onClick={() => handleChooseApp(el.value)}>
                      {el?.label.toUpperCase()}
                    </Button>
                  </Box>
                );
              })}
            </>
          ) : (
            <Box>
              <Skeleton my={10} height={70} width="100%" radius="md" mb="md" />
              <Skeleton my={10} height={70} width="100%" radius="md" mb="md" />
              <Skeleton my={10} height={70} width="100%" radius="md" mb="md" />
            </Box>
          )}
        </Box>
      ) : (
        <Box mb="md">
          <FormFeatureOrg params={params} onCloseModal={onCloseModal} handleBackApp={handleBackApp} />
        </Box>
      )}

      {params.applicationId === "" && (
        <Box mt={26}>
          <Flex justify="flex-end">
            <Group>
              <Button variant="outline" color="indigo.9" onClick={onCloseModal}>
                Tutup
              </Button>
            </Group>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default FormApp;
