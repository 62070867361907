import React, { useEffect, useMemo, useState } from "react";

// mantine ui & image
import classes from "./accountLogin.module.css";
import Logo from "../../assets/img/logo tandeem.png";
import { Anchor, Badge, Box, Button, Card, Group, Image, PasswordInput, Text, TextInput, rem } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useParams } from "react-router-dom";
import { notificationError, notificationSuccess } from "../../components/ui/Notification";
import Cookies from "universal-cookie";
import http from "../../plugins/axios";
import { login } from "../../services/auth";
import { validation } from "../../plugins/validation";
import CryptoJS from "crypto-js";
import LoggedIn from "./LoggedIn";

const initialVal = {
  nickname: "",
  username: "",
  password: "",
  applicationId: "",
};

const formValidation = {
  nickname: {
    isError: false,
    message: "",
  },
  username: {
    isError: false,
    message: "",
  },
  password: {
    isError: false,
    message: "",
  },
  applicationId: {
    isError: false,
    message: "",
  },
};

const AccountLogin = () => {
  // const cookies = new Cookies();
  const cookies = useMemo(() => new Cookies(), []);
  const cryptoKey = "30125c4f90b4b958";
  const params = useParams();
  const [formData, setFormData] = useState(initialVal);
  const [loading, setLoading] = useState(false);
  const [validationForm, setValidationForm] = useState(formValidation);
  const [errMessage, setErrMessage] = useState(null);
  const isMobile = useMediaQuery("(max-width: 430px)");
  const appId = params.id.split("appId=")[1];
  const [dataApp, setDataApp] = useState(false);
  const domain = window.location.hostname;

  useEffect(() => {
    const token = cookies.get("token_account_tandeem");
    if (token) {
      setDataApp(true);
    }
    //eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setValidationForm(formValidation);
    const payload = {
      nickname: formData.nickname,
      username: formData.username,
      password: formData.password,
      applicationId: appId,
    };
    const isErrorForm = validation(payload, setValidationForm);
    if (isErrorForm) {
      setLoading(false);
      return;
    }
    try {
      const response = await login(payload);
      if (response) {
        console.log(response);
        notificationSuccess("Login Berhasil", `Selamat datang ${response.fullName}`);
        http.defaults.headers.common["Authorization"] = `Bearer ${response.access_token}`;
        const dataLogin = {
          email: response.email,
          fullName: response.fullName,
          id: response.id,
          organization: response.organization,
          role: response.role,
          roleAlias: response.roleAlias,
          scope: response.scope,
          user: response.user,
        };
        const cipherText = CryptoJS.AES.encrypt(
          JSON.stringify(response.access_token),
          // SECREAT_KEY
          cryptoKey
        ).toString();
        console.log(cipherText);
        cookies.set("token_account_tandeem", response.access_token, { path: "/", domain: domain === "localhost" ? "localhost" : "tandeem.co.id" });
        cookies.set("authData", dataLogin, { path: "/", domain: domain === "localhost" ? "localhost" : "tandeem.co.id" });
        cookies.set("refreshToken_account_tandeem", response.refresh_token, { path: "/", domain: domain === "localhost" ? "localhost" : "tandeem.co.id" });
        domain === "localhost" ? (window.location.href = `http://localhost:3001`) : (window.location.href = `https://${response?.application?.url}`);
        // domain === "localhost"
        //   ? (window.location.href = appId === hrisId ? `http://localhost:3002` : appId === membershipId ? `http://localhost:3001` : `http://localhost:3002`)
        //   : (window.location.href = appId === hrisId ? `https://hris.tandeem.co.id` : appId === membershipId ? `https://membership.tandeem.co.id` : `https://erp.tandeem.co.id`);
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      setErrMessage(errorMessage || "");
      notificationError("Login Gagal", `${errorMessage ? errorMessage : "Silahkan periksa kembali form anda"}`);
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el,
              },
            }));
          }
        });
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className={classes.body}>
      {!dataApp ? (
        <Box className={classes.card}>
          <Card shadow="lg" padding="lg" radius="lg" w={isMobile ? 280 : 400}>
            <Box className={classes.logo}>
              <Image src={Logo} alt="logo" />
            </Box>

            <Group mt="md" mb="xs">
              <Text fw={400}>Enter nickname, email, and password to sign in!</Text>
            </Group>

            <Box>
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <Box mb={20}>
                  <Box mb="sm">
                    <TextInput
                      radius="md"
                      name="nickname"
                      label="Organization Nickname"
                      placeholder="Input organization nickname"
                      error={validationForm.nickname.isError ? `${validationForm.nickname.message}` : ""}
                      onChange={handleChange}
                      withAsterisk
                    />
                  </Box>
                  <Box mb="sm">
                    <TextInput radius="md" name="username" label="Email" placeholder="Input email" error={validationForm.username.isError ? `${validationForm.username.message}` : ""} onChange={handleChange} withAsterisk />
                  </Box>
                  <Box mb="sm">
                    <PasswordInput radius="md" name="password" placeholder="Input Password" label="Password" error={validationForm.password.isError ? `${validationForm.password.message}` : ""} onChange={handleChange} withAsterisk />
                  </Box>
                  <Box>
                    <Group mb="xs" justify="right">
                      <Anchor size="sm" fw={800} c="blue" className={classes.textLink} href={`/authentication/account/forgot-password/${appId}`}>
                        Forgot Password?
                      </Anchor>
                    </Group>
                  </Box>
                  {errMessage && (
                    <Box mb="sm" ta="center">
                      <Badge size="md" variant="outline" color="red" radius="lg" fw={600} border={`${rem(1)} solid red`}>
                        {errMessage}
                      </Badge>
                    </Box>
                  )}
                </Box>
                <Box>
                  <Group grow>
                    <Button className={classes.button} disabled={!formData.username || !formData.password || !formData.nickname} loading={loading} variant="filled" radius="md" size="md" color="#0472b9" type="submit">
                      Sign In
                    </Button>
                  </Group>
                </Box>
              </form>
            </Box>
          </Card>
        </Box>
      ) : (
        <Box>
          <Box className={classes.body}>
            <LoggedIn appId={appId} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AccountLogin;
