import React, { useEffect, useState } from "react";
import classes from "./settingItem.module.css";
import Sidebar from "../../components/ui/AppShell/Sidebar";
import { Box, Flex, Modal, Pagination, ScrollArea, Select, Text, TextInput, rem } from "@mantine/core";
import { getListSettingItem } from "../../services/settingItem";
import { IconSearch } from "@tabler/icons-react";
import { useMediaQuery } from "@mantine/hooks";
import FormSettingItem from "../../components/pages/settingItem/FormSettingItem";
import TableSkeleton from "../../components/ui/TableSkeleton";
import TableSettingItem from "../../components/pages/settingItem/TableSettingItem";

const defaultParameter = {
  skip: 0,
  take: 10,
  orderBy: "name",
  order: "desc",
  keywords: "",
  isActive: "",
};

const labelTable = [
  {
    label: "No.",
    width: 30,
  },
  {
    label: "Setting Item",
    width: "auto",
  },
  {
    label: "Default Value",
    width: "auto",
  },
  {
    label: "Status",
    width: "auto",
  },
  {
    label: "Application",
    width: "auto",
  },
  {
    label: "Action",
    width: 100,
  },
];

const SettingItem = () => {
  const [params, setParams] = useState(defaultParameter);
  const [settingItemList, setSettingItemList] = useState([]);
  const [count, setCount] = useState(0);
  const [detailData, setDetailData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openModalForm, setOpenModalForm] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 425px)");
  const [selectedStatus, setSelectedStatus] = useState("");
  const dataSelect = [
    { value: "true", label: "Active" },
    { value: "false", label: "Non Active" },
  ];

  const onCloseModalForm = () => {
    setOpenModalForm(false);
    setDetailData(null);
  };

  const handleGetSettingItem = async () => {
    setLoading(true);
    try {
      const response = await getListSettingItem(params);
      setSettingItemList(response.data);
      setCount(response.count);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetSettingItem();
    // eslint-disable-next-line
  }, [selectedStatus]);

  useEffect(() => {
    handleGetSettingItem();
    // eslint-disable-next-line
  }, [params]);

  const handleSearchChange = (e) => {
    const params = {
      skip: 0,
      take: 10,
      orderBy: "name",
      order: "desc",
      keywords: e.target.value,
      isActive: "",
    };
    setParams(params);
  };

  const handleChangePage = (e) => {
    const params = {
      skip: (e - 1) * 10,
      take: 10,
      orderBy: "name",
      order: "desc",
      keywords: "",
      isActive: "",
    };
    setParams(params);
  };

  const handleChangeStatus = (e) => {
    setSelectedStatus(e);
    const params = {
      skip: 0,
      take: 10,
      orderBy: "name",
      order: "desc",
      keywords: "",
      isActive: selectedStatus !== "true" ? true : false,
    };
    setParams(params);
  };

  const actions = {
    edit: (val) => {
      setOpenModalForm(true);
      setDetailData(val);
    },
  };

  const handleAction = (val, type) => {
    return actions[type](val);
  };

  return (
    <Sidebar>
      <Box>
        <Text className={classes.titlePage} mb={20}>
          Setting Item
        </Text>

        <Box style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
          <Flex>
            <TextInput mr={8} leftSectionPointerEvents="none" leftSection=<IconSearch style={{ width: rem(16), height: rem(16) }} /> placeholder="Cari setting item. . ." name="search" onChange={handleSearchChange} />
            <Select mr={8} placeholder="Select Status" data={dataSelect} onChange={handleChangeStatus} value={selectedStatus} />{" "}
          </Flex>
        </Box>

        <Box my={20}>{loading ? <TableSkeleton total={5} /> : <TableSettingItem label={labelTable} data={settingItemList} countData={params.skip} actionMethod={handleAction} />}</Box>
        <Flex justify="end">
          <Pagination onChange={handleChangePage} total={Math.ceil(count / params.take) || 0} color="#0572b9" radius="lg" />
        </Flex>
        <Modal
          opened={openModalForm}
          onClose={onCloseModalForm}
          centered
          closeOnClickOutside={false}
          size="lg"
          title={<Text fw="Bold">{detailData ? "Edit Setting Item" : "Tambah Setting Item"}</Text>}
          scrollAreaComponent={ScrollArea.Autosize}
          withCloseButton={false}
        >
          <FormSettingItem dataSettingItem={detailData} onCloseModal={onCloseModalForm} reloadList={handleGetSettingItem} />
        </Modal>
      </Box>
    </Sidebar>
  );
};

export default SettingItem;
