import React, { useEffect, useState } from "react";
import classes from "./organization.module.css";
import Sidebar from "../../components/ui/AppShell/Sidebar";
import { Box, Button, Flex, Modal, Pagination, ScrollArea, Text, TextInput, rem } from "@mantine/core";
import { IconCopyPlus, IconSearch } from "@tabler/icons-react";
import { useMediaQuery } from "@mantine/hooks";
import TableSkeleton from "../../components/ui/TableSkeleton";
import TableOrganization from "../../components/pages/organization/TableOrganization";
import { getListOrganization } from "../../services/organization";
import FormOrganization from "../../components/pages/organization/FormOrganization";
import FormApp from "../../components/pages/organization/FormApp";

const defaultParameter = {
  skip: 0,
  take: 10,
  keyword: "",
  orderBy: "name",
};

const labelTable = [
  {
    label: "No.",
    width: 30,
  },
  {
    label: "Nama",
    width: "auto",
  },
  {
    label: "Email",
    width: "auto",
  },
  {
    label: "Created At",
    width: "auto",
  },
  {
    label: "Status",
    width: "auto",
  },
  {
    label: "Action",
    width: 100,
  },
];

const Organization = () => {
  const [params, setParams] = useState(defaultParameter);
  const [organizationList, setOrganizationList] = useState([]);
  const [count, setCount] = useState(0);
  const [detailData, setDetailData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openModalForm, setOpenModalForm] = useState(false);
  const [openModalFormFeature, setOpenModalFormFeature] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 425px)");

  const handleGetOrganizationList = async () => {
    setLoading(true);
    try {
      const response = await getListOrganization(params);
      setOrganizationList(response.data);
      setCount(response.count);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetOrganizationList();
    // eslint-disable-next-line
  }, [params]);

  const handleSearchChange = (e) => {
    const params = {
      skip: 0,
      take: 10,
      keyword: e.target.value,
      orderBy: "name",
    };
    setParams(params);
  };

  const handleChangePage = (e) => {
    const params = {
      skip: (e - 1) * 10,
      take: 10,
      keyword: "",
      orderBy: "name",
    };
    setParams(params);
  };

  const onCloseModalForm = () => {
    setOpenModalForm(false);
    setDetailData(null);
  };

  const onCloseModalFormFeature = () => {
    setOpenModalFormFeature(false);
    setDetailData(null);
  };

  const actions = {
    edit: (val) => {
      setOpenModalForm(true);
      setDetailData(val);
    },
    feature: (val) => {
      setOpenModalFormFeature(true);
      setDetailData(val);
    },
  };

  const handleAction = (val, type) => {
    return actions[type](val);
  };

  return (
    <Sidebar>
      <Box>
        <Text className={classes.titlePage} mb={20}>
          Organization Management
        </Text>
      </Box>
      <Box style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
        <Flex>
          <TextInput leftSectionPointerEvents="none" leftSection=<IconSearch style={{ width: rem(16), height: rem(16) }} /> placeholder="Cari Organisasi. . ." name="search" onChange={handleSearchChange} />
        </Flex>
        <Flex mt={isSmallScreen ? 10 : 0}>
          <Button fullWidth={isSmallScreen} leftSection={<IconCopyPlus size={14} />} color="#0572b9" onClick={() => setOpenModalForm(true)}>
            Tambah Organisasi
          </Button>
        </Flex>
      </Box>
      <Box my={20}>{loading ? <TableSkeleton total={5} /> : <TableOrganization label={labelTable} data={organizationList} countData={params.skip} actionMethod={handleAction} />}</Box>
      <Flex justify="end">
        <Pagination onChange={handleChangePage} total={Math.ceil(count / params.take) || 0} color="#0572b9" radius="lg" />
      </Flex>
      <Modal
        opened={openModalForm}
        onClose={onCloseModalForm}
        centered
        closeOnClickOutside={false}
        size="lg"
        title={<Text fw="Bold">{detailData ? "Edit Organisasi" : "Tambah Organisasi"}</Text>}
        scrollAreaComponent={ScrollArea.Autosize}
        withCloseButton={false}
      >
        <FormOrganization dataOrganization={detailData} onCloseModal={onCloseModalForm} reloadList={handleGetOrganizationList} />
      </Modal>
      <Modal
        opened={openModalFormFeature}
        onClose={onCloseModalFormFeature}
        centered
        closeOnClickOutside={false}
        size="lg"
        title={<Text fw="Bold">Setting Fitur Organisasi</Text>}
        scrollAreaComponent={ScrollArea.Autosize}
        withCloseButton={false}
      >
        <FormApp dataOrganization={detailData} onCloseModal={onCloseModalFormFeature} reloadList={handleGetOrganizationList} />
      </Modal>
    </Sidebar>
  );
};

export default Organization;
