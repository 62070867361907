import React from "react";
import Sidebar from "../../ui/AppShell/Sidebar";
import { Box, Text } from "@mantine/core";

const Dashboard = () => {
  return (
    <Sidebar>
      <Box>
        <Text fw={700} mb={20} fz={20}>
          Welcome to SSO Admin!
        </Text>
      </Box>
    </Sidebar>
  );
};

export default Dashboard;
