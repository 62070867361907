import React from "react";
import { useDisclosure } from "@mantine/hooks";
import { AppShell, Box, Flex, ScrollArea, Text, ThemeIcon } from "@mantine/core";
import SidebarMenu from "../SidebarMenu";
import Header from "../Header";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import { IconLogout } from "@tabler/icons-react";
import { update } from "../../../../store/authData";
import ProtectedRoute from "../../../../middleware/protectedRoute";
import classes from "../SidebarMenu/sidebarMenu.module.css";

const Sidebar = ({ children }) => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const [openSidebarDesktop, { toggle: toggleDesktop }] = useDisclosure(true);
  const [openSidebarMobile, { toggle: toggleMobile }] = useDisclosure();

  const handleLogout = () => {
    cookies.remove("authData", { path: "/" });
    cookies.remove("token", { path: "/" });
    cookies.remove("refreshToken", { path: "/" });
    dispatch(update({ dataUser: null, isLogin: false }));
    setTimeout(() => {
      cookies.remove("token", { path: "/" });
      cookies.remove("refreshToken", { path: "/" });
      window.location.href = "/authentication/sign-in";
    }, 200);
  };

  return (
    <ProtectedRoute>
      <AppShell
        header={{ height: { base: 60, md: 60, lg: 70 } }}
        navbar={{
          width: { base: 200, md: 250, lg: 280 },
          breakpoint: "sm",
          collapsed: { mobile: !openSidebarMobile, desktop: !openSidebarDesktop },
        }}
        padding="md"
      >
        <AppShell.Header>
          <Header mobileToggle={toggleMobile} desktopToggle={toggleDesktop} />
        </AppShell.Header>
        <AppShell.Navbar p="md">
          <AppShell.Section grow component={ScrollArea}>
            <SidebarMenu />
          </AppShell.Section>
          <AppShell.Section>
            <Box p="lg" style={{ borderTop: "1px solid gainsboro" }} className={classes.link}>
              <Flex ml={12} align="center" style={{ cursor: "pointer" }} onClick={handleLogout}>
                <ThemeIcon variant="light" color="#0572b9" size="md" radius="md" mr={15}>
                  <IconLogout stroke={1.5} size={18} />
                </ThemeIcon>
                <Text fz={13} fw="bold" c="#0572b9">
                  Log out
                </Text>
              </Flex>
            </Box>
          </AppShell.Section>
        </AppShell.Navbar>
        <AppShell.Main>{children}</AppShell.Main>
      </AppShell>
    </ProtectedRoute>
  );
};

export default Sidebar;
