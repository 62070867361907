import { createSlice } from "@reduxjs/toolkit";

const permissionRoute = createSlice({
  name: "permission",
  initialState: {
    access: null,
  },
  reducers: {
    updatedRoute: (state, action) => {
      state.access = action.payload.access;
    },
  },
});

export const { updatedRoute } = permissionRoute.actions;
export default permissionRoute.reducer;
