import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Group, Switch, Text, TextInput } from "@mantine/core";
import { useLocation } from "react-router-dom";
import { validation } from "../../../plugins/validation";
import { addPermissionDefault, updatePermissionDefault } from "../../../services/permissionDefault";
import { notificationError, notificationSuccess } from "../../ui/Notification";

const defaultVal = {
  name: "",
  alias: "",
  isActive: false,
  isForAll: false,
  applicationId: "",
};

const formValidation = {
  name: {
    isError: false,
    message: "",
  },
  alias: {
    isError: false,
    message: "",
  },
  isActive: {
    isError: false,
    message: "",
  },
  isForAll: {
    isError: false,
    message: "",
  },
  applicationId: {
    isError: false,
    message: "",
  },
};

const FormPermission = ({ dataPermission, onCloseModal, reloadList }) => {
  const [loadingForm, setLoadingForm] = useState(false);
  const [formData, setFormData] = useState(defaultVal);
  const [validationForm, setValidationForm] = useState(formValidation);
  const route = useLocation().pathname.split("/").slice(1);
  const id = route[route.length - 1];
  console.log(formData);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSwitchActive = () => {
    setFormData({ ...formData, isActive: !formData.isActive });
  };

  const handleSwitchForAll = () => {
    setFormData({ ...formData, isForAll: !formData.isForAll });
  };

  useEffect(() => {
    if (dataPermission !== null) {
      handleSetForm(dataPermission);
    }
    // eslint-disable-next-line
  }, [dataPermission]);

  const handleSetForm = (dataPermission) => {
    const dataDetail = {
      name: dataPermission?.name,
      alias: dataPermission?.alias,
      isActive: Boolean(dataPermission?.isActive),
      isForAll: Boolean(dataPermission?.isForAll),
      applicationId: id,
    };
    setFormData(dataDetail);
  };

  const handleSubmit = async (data) => {
    let methodFunction = null;
    let titleMessageSuccess = "";
    let captionMessageSuccess = "";
    let titleMessageError = "";
    let captionMessageError = "";
    setLoadingForm(true);
    setValidationForm(formValidation);
    const payload = {
      name: data.name,
      alias: data.alias,
      isActive: Boolean(data.isActive),
      isForAll: Boolean(data.isForAll),
      applicationId: id,
    };
    const isError = validation(payload, setValidationForm);
    if (isError) {
      setLoadingForm(false);
      return;
    }
    if (dataPermission !== null) {
      methodFunction = updatePermissionDefault(dataPermission?.id, payload);
      titleMessageSuccess = "Update Permission Berhasil";
      captionMessageSuccess = "Anda telah berhasil mengupdate data Permission";
      titleMessageError = "Gagal Mengupdate Permission";
      captionMessageError = "Silahkan cek kembali form anda";
    } else {
      methodFunction = addPermissionDefault(payload);
      titleMessageSuccess = "Tambah Permission Berhasil";
      captionMessageSuccess = "Anda telah berhasil menambahkan Permission baru";
      titleMessageError = "Gagal Menambahkan Permission";
      captionMessageError = "Silahkan cek kembali form anda";
    }
    try {
      const response = await methodFunction;
      if (response) {
        onCloseModal();
        reloadList();
        notificationSuccess(titleMessageSuccess, captionMessageSuccess);
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      notificationError(titleMessageError, `${Object.keys(errorMessage) ? errorMessage : captionMessageError}`);
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el,
              },
            }));
          }
        });
      });
    } finally {
      setLoadingForm(false);
    }
  };

  return (
    <Box>
      <Box mb="md">
        <TextInput name="name" value={formData.name} label="Nama Permission" placeholder="Masukkan nama Permission" error={validationForm.name.isError ? `${validationForm.name.message}` : ""} onChange={handleChange} withAsterisk />
      </Box>
      <Box mb="md">
        <TextInput name="alias" value={formData.alias} label="Alias" placeholder="Masukkan alias" error={validationForm.alias.isError ? `${validationForm.alias.message}` : ""} onChange={handleChange} withAsterisk />
      </Box>

      <Box mb="md">
        <Flex justify="flex-start">
          <Text fz={14} mx={3} mr={8}>
            Active :{" "}
          </Text>
          <Text fz={14} mx={3} mr={8} c="red">
            {validationForm?.isActive?.isError ? `${validationForm?.isActive?.message}` : ""}
          </Text>
          <Switch onLabel="YES" offLabel="NO" checked={formData.isActive} onChange={handleSwitchActive} />
        </Flex>
      </Box>
      <Box mb="md">
        <Flex justify="flex-start">
          <Text fz={14} mx={3} mr={8}>
            For All :{" "}
          </Text>
          <Text fz={14} mx={3} mr={8} c="red">
            {validationForm?.isForAll?.isError ? `${validationForm?.isForAll?.message}` : ""}
          </Text>
          <Switch onLabel="YES" offLabel="NO" checked={formData.isForAll} onChange={handleSwitchForAll} />
        </Flex>
      </Box>
      <Box mt={20}>
        <Flex justify="flex-end">
          <Group>
            <Button variant="outline" color="indigo.9" onClick={onCloseModal}>
              Tutup
            </Button>
            <Button loading={loadingForm} onClick={() => handleSubmit(formData)} variant="filled" color="indigo.9">
              Simpan
            </Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  );
};

export default FormPermission;
