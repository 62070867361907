import axios from "axios";
import Cookies from "universal-cookie";
// import { decodeText } from "./encrypt";

const cookies = new Cookies();
const sessionLogin = cookies.get("token");

// const generateToken = (session) => {
//   let token = "";
//   if (session) {
//     const decode = decodeText(session);
//     token = JSON.parse(decode);
//   }
//   return token;
// };

const http = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_API_URL}`,
});

const getSessionLogin = sessionLogin;
if (getSessionLogin) {
  http.defaults.headers.common["Authorization"] = `Bearer ${getSessionLogin}`;
}

http.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.data.message === "Unauthorized") {
      cookies.remove("token");
      window.location.reload();
    }
    throw error;
  }
);

export default http;
