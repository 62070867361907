import React from "react";
import { Table, Text } from "@mantine/core";
import { TimeFormatter, parseDateLocal } from "../../../plugins/helper";

const TableHistoryWeb = ({ label, data, countData }) => {
  const mappingTableHead = (data) =>
    data.map((val, index) => {
      return (
        <Table.Th key={index} width={val.width !== "auto" ? val.width : ""}>
          {val.label}
        </Table.Th>
      );
    });

  const mappingDataTable = (data) =>
    data.map((val, index) => {
      return (
        <Table.Tr key={index}>
          <Table.Td>{index + countData + 1}.</Table.Td>
          <Table.Td>
            <Text fz={13}>{val.user.fullName}</Text>
            <Text fz={12}>{val.user.email}</Text>
          </Table.Td>
          <Table.Td>
            <Text fz={13}>{val?.application !== null ? val?.application?.url : "-"}</Text>
            <Text fz={12}>IP : {val.ip}</Text>
          </Table.Td>
          <Table.Td>
            <Text fz={13}>{parseDateLocal(val.createdAt)}</Text>
            <Text fz={12}>{TimeFormatter(val.createdAt)}</Text>
          </Table.Td>
        </Table.Tr>
      );
    });

  return (
    <Table.ScrollContainer minWidth={768}>
      <Table horizontalSpacing="sm" withTableBorder style={{ fontSize: "13px" }}>
        <Table.Thead>
          <Table.Tr>{mappingTableHead(label)}</Table.Tr>
        </Table.Thead>
        <Table.Tbody>{mappingDataTable(data)}</Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
};

export default TableHistoryWeb;
