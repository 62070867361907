import http from "../plugins/axios";

export const getListDatabaseApp = async (params) => {
  try {
    const response = await http.get(`/application-database`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addDatabaseApp = async (payload) => {
  try {
    const response = await http.post(`/application-database`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateDatabaseApp = async (id, payload) => {
  try {
    const response = await http.patch(`/application-database/update/${id}`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};
