import http from "../plugins/axios";

export const getListAppApiKey = async (params) => {
  try {
    const response = await http.get(`/application-api-key`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addAppApiKey = async (payload) => {
  try {
    const response = await http.post(`/application-api-key`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateAppApiKey = async (id, payload) => {
  try {
    const response = await http.patch(`/application-api-key/update/${id}`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};
