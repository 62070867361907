import http from "../plugins/axios";

export const getListPermissionDefault = async (id) => {
  try {
    const response = await http.get(`/permissions?applicationId=${id || ""}`);
    console.log(response);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getListPermissionAllDefault = async (applicationId, take, skip, keyword) => {
  try {
    const response = await http.get(`/permissions/all?applicationId=${applicationId || ""}&take=${take || 10}&skip=${skip || 0}&keyword=${keyword || ""}&orderBy=name&order=ASC`);
    console.log(response);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getListPermissionOrg = async (id) => {
  try {
    const response = await http.get(`/permissions?applicationId=${id || ""}`);
    console.log(response);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addPermissionDefault = async (payload) => {
  try {
    const response = await http.post(`/permissions`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updatePermissionDefault = async (id, payload) => {
  try {
    const response = await http.patch(`/permissions/update/${id}`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};
