const MENU_PERMISSION = {
  dashboard: "dashboard",
  permission: "permission",
  user: "user",
  role: "role",
  company: "company",
  organization: "organization",
  org: "org",
  system: "system",
  systemOrg: "system-org",
  noScope: "",
};

export { MENU_PERMISSION };
