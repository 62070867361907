import http from "../plugins/axios";

export const getListFeature = async (params) => {
  try {
    const response = await http.get(`/feature`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDetailFeature = async (id) => {
  try {
    const response = await http.get(`/feature/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addFeature = async (payload) => {
  try {
    const response = await http.post(`/feature`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateFeature = async (id, payload) => {
  try {
    const response = await http.patch(`/feature/update/${id}`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};
