import { Box, Skeleton } from "@mantine/core";
import React from "react";

const FormSkeleton = ({ total }) => {
  const mappingFormSkeleton = (number) => {
    return Array.from({ length: number }, (_, i) => (
      <React.Fragment key={i}>
        <Skeleton height={16} width="20%" mb="sm" />
        <Skeleton height={28} mb="md" />
      </React.Fragment>
    ));
  };

  return <Box>{mappingFormSkeleton(total)}</Box>;
};

export default FormSkeleton;
