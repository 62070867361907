import React, { useEffect, useState } from "react";
import { Badge, Box, Button, Card, CheckIcon, Group, Image, PasswordInput, Popover, Progress, Text, rem } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import Cookies from "universal-cookie";

// mantine ui & image
import classes from "./resetPasswordAccount.module.css";
import Logo from "../../assets/img/logo tandeem.png";
import PasswordRequired from "../../components/ui/PasswordRequired";
import { validation } from "../../plugins/validation";
import { notificationError, notificationSuccess } from "../../components/ui/Notification";
import { resetPassword } from "../../services/auth";
import { useParams } from "react-router-dom";
import { IconCircleCheck } from "@tabler/icons-react";

const initialVal = {
  password: "",
  confirmPassword: "",
};

const formValidation = {
  password: {
    isError: false,
    message: "",
  },
  confirmPassword: {
    isError: false,
    message: "",
  },
};

const requirements = [
  { re: /[0-9]/, label: "Password harus mencantumkan angka" },
  { re: /[a-z]/, label: "Password harus mencantumkan huruf kecil" },
  { re: /[A-Z]/, label: "Password harus mencantumkan huruf besar" },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Password harus mencantumkan simbol" },
];

function getStrength(password) {
  let multiplier = password?.length > 7 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}

const ResetPasswordAccount = () => {
  const cookies = new Cookies();
  let { token } = useParams();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialVal);
  const [validationForm, setValidationForm] = useState(formValidation);
  const [errMessage, setErrMessage] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const isMobile = useMediaQuery("(max-width: 430px)");
  const [popoverPassword, setPopoverPassword] = useState(false);
  const checkPassword = requirements.map((val, index) => <PasswordRequired key={index} label={val.label} meets={val.re.test(formData.password)} />);
  const strengthPassword = getStrength(formData?.password);

  const progressBar = Array(4)
    .fill(0)
    .map((_, index) => {
      return (
        <Progress
          styles={{ section: { transitionDuration: "0ms" } }}
          value={Object.values(formData?.password).length > 0 && index === 0 ? 100 : strengthPassword >= ((index + 1) / 4) * 100 ? 100 : 0}
          color={strengthPassword > 80 ? "teal" : strengthPassword > 50 ? "yellow" : "red"}
          key={index}
          size={4}
        />
      );
    });

  useEffect(() => {
    cookies.remove("token");
    cookies.remove("refreshToken");
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const FormPassword = () => {
    return (
      <Box>
        <Box mb="md">
          <Popover opened={popoverPassword} position="bottom" width="target" transitionProps={{ transition: "pop" }}>
            <Popover.Target>
              <Box onFocusCapture={() => setPopoverPassword(true)} onBlurCapture={() => setPopoverPassword(false)}>
                <PasswordInput
                  radius="md"
                  name="password"
                  value={formData.password}
                  placeholder="Masukkan password"
                  label="Password"
                  error={validationForm.password.isError ? `${validationForm.password.message}` : ""}
                  onChange={handleChange}
                  withAsterisk
                />
              </Box>
            </Popover.Target>
            <Popover.Dropdown>
              <Group gap={5} grow mt="xs" mb="md">
                {progressBar}
              </Group>
              <PasswordRequired label="Password harus lebih dari 7 karakter" meets={formData.password.length > 7} />
              {checkPassword}
            </Popover.Dropdown>
          </Popover>
        </Box>
        <Box mb="md">
          <PasswordInput
            radius="md"
            value={formData.confirmPassword}
            name="confirmPassword"
            placeholder="Ulangi password anda"
            label="Konfirmasi Password"
            error={validationForm.confirmPassword.isError ? validationForm.confirmPassword.message : ""}
            onChange={handleChange}
            withAsterisk
          />
          {formData.confirmPassword && (
            <>
              {formData?.password !== formData.confirmPassword ? (
                <Text c="red" fz={14} fw={700}>
                  Password Doesn't Match!{" "}
                </Text>
              ) : (
                <Text c="green" fz={14} fw={700}>
                  Password Match <CheckIcon color="green" size={12} />{" "}
                </Text>
              )}
            </>
          )}
        </Box>
      </Box>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setValidationForm(formValidation);
    const isErrorForm = validation(formData, setValidationForm);
    if (isErrorForm) {
      setLoading(false);
      return;
    }
    try {
      const response = await resetPassword(token, { password: formData.password });
      if (response) {
        setIsSuccess(true);
        notificationSuccess("Password Berhasil Diperbarui", `${response.data.message}`);
        setTimeout(() => {
          window.location.href = `https://hris.tandeem.co.id`;
        }, 5000);
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      setErrMessage(errorMessage);
      notificationError("Password gagal diperbarui", `${errorMessage}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className={classes.body}>
      <Box className={classes.card}>
        <Card shadow="sm" padding="lg" w={isMobile ? 280 : 400}>
          {isSuccess ? (
            <Group mt="md" mb="xs" justify="center">
              <IconCircleCheck size={50} color="#12af1d" stroke={2} />
              <Text fw={700} size="lg">
                Password berhasil diubah, tunggu beberapa saat. Anda akan diarahkan ke halaman login...
              </Text>
            </Group>
          ) : (
            <>
              <Box className={classes.logo}>
                <Image src={Logo} alt="logo" />
              </Box>
              <Group mt="md" mb="xs">
                <Text fw={400} size="sm">
                  Masukkan password baru pada form di bawah ini untuk mengubah password
                </Text>
              </Group>
              <Box>
                <form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  {FormPassword()}
                  {errMessage && (
                    <Box mb="sm" ta="center">
                      <Badge size="md" variant="outline" color="red" radius="lg" fw={600} border={`${rem(1)} solid red`}>
                        {errMessage}
                      </Badge>
                    </Box>
                  )}
                  <Box>
                    <Group grow>
                      <Button className={classes.button} disabled={!formData.password || !formData.confirmPassword} variant="light" radius="md" size="md" loading={loading} color="#0472b9" type="submit">
                        Submit
                      </Button>
                    </Group>
                  </Box>
                </form>
              </Box>
            </>
          )}
        </Card>
      </Box>
    </Box>
  );
};

export default ResetPasswordAccount;
