import http from "../plugins/axios";

export const getListOrganization = async (params) => {
  try {
    const response = await http.get(`/organizations`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDetailOrganization = async (id) => {
  try {
    const response = await http.get(`/organizations/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addOrganization = async (payload) => {
  try {
    const response = await http.post(`/organizations/with-user`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateOrganization = async (id, payload) => {
  try {
    const response = await http.patch(`/organizations/${id}`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const checkNicknameOrganization = async (payload) => {
  try {
    const response = await http.post(`/organizations/check-nickname`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};
