import React, { useEffect, useState } from "react";
import classes from "./roleDefault.module.css";
import Sidebar from "../../components/ui/AppShell/Sidebar";
import { Box, Button, Flex, Modal, Pagination, ScrollArea, Select, Skeleton, Text, TextInput, rem } from "@mantine/core";
import { IconCopyPlus, IconSearch } from "@tabler/icons-react";
import TableSkeleton from "../../components/ui/TableSkeleton";
import TableRoleDefault from "../../components/pages/roleDefault/TableROleDefault";
import { useMediaQuery } from "@mantine/hooks";
import { getListRoleDefault } from "../../services/roleDefault";
import FormRoleDefault from "../../components/pages/roleDefault/FormRoleDefault";
import FormRolePermission from "../../components/pages/roleDefault/FormRolePermission";
import { getListApplication } from "../../services/application";

const defaultParameter = {
  skip: 0,
  take: 10,
  keyword: "",
  applicationId: "",
};

const labelTable = [
  {
    label: "No.",
    width: 30,
  },
  {
    label: "Role",
    width: "auto",
  },
  {
    label: "Application",
    width: "auto",
  },
  {
    label: "Status",
    width: "auto",
  },
  {
    label: "Action",
    width: 100,
  },
];

const RoleDefault = () => {
  const [loadingForm, setLoadingForm] = useState(false);
  const [params, setParams] = useState(defaultParameter);
  const [roleDefaultList, setRoleDefaultList] = useState([]);
  const [count, setCount] = useState(0);
  const [detailData, setDetailData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openModalForm, setOpenModalForm] = useState(false);
  const [openModalPermission, setOpenModalPermission] = useState(false);
  const [selectedApplicationId, setSelectedApplicationId] = useState("");
  const [applicationList, setApplicationList] = useState([]);
  const isSmallScreen = useMediaQuery("(max-width: 425px)");

  const handleGetListApplication = async () => {
    setLoadingForm(true);
    try {
      const response = await getListApplication();
      const dataApplication = response.data;
      const mappingApplication = [
        { value: "", label: "Select aplikasi" },
        ...dataApplication.map((val) => ({
          value: val?.id?.toString(),
          label: val?.name,
        })),
      ];
      setApplicationList(mappingApplication);
    } catch (error) {
      console.log(error);
    }
    setLoadingForm(false);
  };

  const handleGetRoleDefault = async () => {
    setLoading(true);
    try {
      const response = await getListRoleDefault(params);
      setRoleDefaultList(response.data);
      setCount(response.count);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetListApplication();
    // eslint-disable-next-line
  }, [selectedApplicationId]);

  useEffect(() => {
    handleGetRoleDefault();
    // eslint-disable-next-line
  }, [params]);

  const handleSearchChange = (e) => {
    const params = {
      skip: 0,
      take: 10,
      keyword: e.target.value,
      applicationId: "",
    };
    setParams(params);
  };

  const handleChangePage = (e) => {
    const params = {
      skip: (e - 1) * 10,
      take: 10,
      keyword: "",
      applicationId: "",
    };
    setParams(params);
  };

  const handleChangeApp = (e) => {
    setSelectedApplicationId(e);
    const params = {
      skip: 0,
      take: 10,
      keyword: "",
      applicationId: e,
    };
    setParams(params);
  };

  const onCloseModalForm = () => {
    setOpenModalForm(false);
    setDetailData(null);
  };

  const onCloseModalPermission = () => {
    setOpenModalPermission(false);
    setDetailData(null);
  };

  const actions = {
    edit: (val) => {
      setOpenModalForm(true);
      setDetailData(val);
    },
    permission: (val) => {
      setOpenModalPermission(true);
      setDetailData(val);
    },
  };

  const handleAction = (val, type) => {
    return actions[type](val);
  };

  return (
    <Sidebar>
      <Box>
        <Text className={classes.titlePage} mb={20}>
          Role Default Management
        </Text>

        {!loadingForm ? (
          <Box style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
            <Flex>
              <TextInput mr={8} leftSectionPointerEvents="none" leftSection=<IconSearch style={{ width: rem(16), height: rem(16) }} /> placeholder="Cari Role. . ." name="search" onChange={handleSearchChange} />
              <Select mr={8} name="applicationId" placeholder="Select aplikasi" searchable nothingFoundMessage="Aplikasi tidak ditemukan" data={applicationList} onChange={handleChangeApp} value={selectedApplicationId} />
            </Flex>
            <Flex mt={isSmallScreen ? 10 : 0}>
              <Button fullWidth={isSmallScreen} leftSection={<IconCopyPlus size={14} />} color="#0572b9" onClick={() => setOpenModalForm(true)}>
                Tambah Role
              </Button>
            </Flex>
          </Box>
        ) : (
          <Box>
            <Skeleton height={40} width="100%" radius="md" mb="md" />
          </Box>
        )}
        <Box my={20}>{loading ? <TableSkeleton total={5} /> : <TableRoleDefault label={labelTable} data={roleDefaultList} countData={params.skip} actionMethod={handleAction} />}</Box>
        <Flex justify="end">
          <Pagination onChange={handleChangePage} total={Math.ceil(count / params.take) || 0} color="#0572b9" radius="lg" />
        </Flex>
        <Modal
          opened={openModalForm}
          onClose={onCloseModalForm}
          centered
          closeOnClickOutside={false}
          size="lg"
          title={<Text fw="Bold">{detailData ? "Edit Role Default" : "Tambah Role Default"}</Text>}
          scrollAreaComponent={ScrollArea.Autosize}
          withCloseButton={false}
        >
          <FormRoleDefault dataRoleDefault={detailData} onCloseModal={onCloseModalForm} reloadList={handleGetRoleDefault} />
        </Modal>
        <Modal opened={openModalPermission} onClose={onCloseModalPermission} centered closeOnClickOutside={false} size="lg" title={<Text fw="Bold">Edit Permission</Text>} scrollAreaComponent={ScrollArea.Autosize} withCloseButton={false}>
          <FormRolePermission dataRoleDefault={detailData} onCloseModal={onCloseModalPermission} reloadList={handleGetRoleDefault} />
        </Modal>
      </Box>
    </Sidebar>
  );
};

export default RoleDefault;
