import http from "../plugins/axios";

export const getListDatabase = async (params) => {
  try {
    const response = await http.get(`/default-database`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addDatabase = async (payload) => {
  try {
    const response = await http.post(`/default-database`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateDatabase = async (id, payload) => {
  try {
    const response = await http.patch(`/default-database/update/${id}`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};
