import http from "../plugins/axios";

export const getListRoleOrg = async (params) => {
  try {
    const response = await http.get(`/role`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addRoleOrg = async (payload) => {
  try {
    const response = await http.post(`/role/create`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateRoleOrg = async (id, payload) => {
  try {
    const response = await http.patch(`/role/update/${id}`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getListRolePermissionOrg = async (id, appId) => {
  try {
    const response = await http.get(`/role-permission/role/${id}?applicationId=${appId || ""}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addRolePermissionOrg = async (payload) => {
  try {
    const response = await http.post(`/role-permission`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const assignRolePermissionOrg = async (payload) => {
  try {
    const response = await http.post(`/role-permission/permissions`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};
