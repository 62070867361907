import http from "../plugins/axios";

export const getListRoleDefault = async (params) => {
  try {
    const response = await http.get(`/role-default`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDetailRoleDefault = async (id) => {
  try {
    const response = await http.get(`/role-default/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addRoleDefault = async (payload) => {
  try {
    const response = await http.post(`/role-default`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateRoleDefault = async (id, payload) => {
  try {
    const response = await http.put(`/role-default/${id}`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getListRolePermissionDefault = async (id, appId) => {
  try {
    const response = await http.get(`/role-permission-default/role/${id}?applicationId=${appId || ""}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addRolePermissionDefault = async (payload) => {
  try {
    const response = await http.post(`/role-permission-default`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const assignRolePermissionDefault = async (payload) => {
  try {
    const response = await http.post(`/role-permission-default/permissions`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};
