import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Group, MultiSelect, Select, Switch, Text, TextInput } from "@mantine/core";
import FormSkeleton from "../../ui/FormSkeleton";
import { getListApplication } from "../../../services/application";
import { validation } from "../../../plugins/validation";
import { addRoleOrg, updateRoleOrg } from "../../../services/roleOrg";
import { notificationError, notificationSuccess } from "../../ui/Notification";

const defaultVal = {
  name: "",
  alias: "",
  isActive: false,
  applicationId: "",
};

const formValidation = {
  name: {
    isError: false,
    message: "",
  },
  alias: {
    isError: false,
    message: "",
  },
  isActive: {
    isError: false,
    message: "",
  },
  applicationId: {
    isError: false,
    message: "",
  },
};

const FormRoleOrg = ({ dataRoleOrg, onCloseModal, reloadList }) => {
  const [loadingForm, setLoadingForm] = useState(false);
  const [formData, setFormData] = useState(defaultVal);
  const [validationForm, setValidationForm] = useState(formValidation);
  const [selectedApplicationId, setSelectedApplicationId] = useState([]);
  const [applicationList, setApplicationList] = useState([]);

  const handleGetListApplication = async () => {
    setLoadingForm(true);
    try {
      const response = await getListApplication();
      const dataApplication = response.data;
      const mappingApplication = dataApplication.map((val) => {
        return {
          value: val?.id?.toString(),
          label: val?.name,
        };
      });
      setApplicationList(mappingApplication);
    } catch (error) {
      console.log(error);
    }
    setLoadingForm(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSwitch = () => {
    setFormData({ ...formData, isActive: !formData.isActive });
  };

  useEffect(() => {
    handleGetListApplication();
    if (dataRoleOrg !== null) {
      handleSetForm(dataRoleOrg);
    }
    // eslint-disable-next-line
  }, [dataRoleOrg]);

  const handleSetForm = (dataRoleOrg) => {
    const dataDetail = {
      name: dataRoleOrg?.name,
      alias: dataRoleOrg?.alias,
      isActive: dataRoleOrg?.isActive,
      applicationId: selectedApplicationId,
    };
    setSelectedApplicationId(dataRoleOrg?.applicationRole?.length > 1 ? dataRoleOrg?.applicationRole?.map((el) => el?.application?.id) : dataRoleOrg?.applicationRole?.[0]?.application?.id);
    setFormData(dataDetail);
  };

  const handleSubmit = async (data) => {
    let methodFunction = null;
    let titleMessageSuccess = "";
    let captionMessageSuccess = "";
    let titleMessageError = "";
    let captionMessageError = "";
    setLoadingForm(true);
    setValidationForm(formValidation);
    const payload = {
      name: data.name,
      alias: data.alias,
      isActive: Boolean(data.isActive),
      applicationId: selectedApplicationId,
    };
    const isError = validation(payload, setValidationForm);
    if (isError) {
      setLoadingForm(false);
      return;
    }
    if (dataRoleOrg !== null) {
      methodFunction = updateRoleOrg(dataRoleOrg?.id, payload);
      titleMessageSuccess = "Update Role Berhasil";
      captionMessageSuccess = "Anda telah berhasil mengupdate data Role";
      titleMessageError = "Gagal Mengupdate Role";
      captionMessageError = "Silahkan cek kembali form anda";
    } else {
      methodFunction = addRoleOrg(payload);
      titleMessageSuccess = "Tambah Role Berhasil";
      captionMessageSuccess = "Anda telah berhasil menambahkan Role baru";
      titleMessageError = "Gagal Menambahkan Role";
      captionMessageError = "Silahkan cek kembali form anda";
    }
    try {
      const response = await methodFunction;
      if (response) {
        onCloseModal();
        reloadList();
        notificationSuccess(titleMessageSuccess, captionMessageSuccess);
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      notificationError(titleMessageError, `${Object.keys(errorMessage) ? errorMessage : captionMessageError}`);
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el,
              },
            }));
          }
        });
      });
    } finally {
      setLoadingForm(false);
    }
  };

  return (
    <Box>
      {!loadingForm ? (
        <>
          <Box mb="md">
            <TextInput name="name" value={formData.name} label="Nama Role" placeholder="Masukkan nama role" error={validationForm.name.isError ? `${validationForm.name.message}` : ""} onChange={handleChange} withAsterisk />
          </Box>
          <Box mb="md">
            <TextInput
              disabled={dataRoleOrg ? true : false}
              name="alias"
              value={formData.alias}
              label="Alias"
              placeholder="Masukkan alias role"
              error={validationForm.alias.isError ? `${validationForm.alias.message}` : ""}
              onChange={handleChange}
              withAsterisk
            />
          </Box>
          <Box mb="md">
            {dataRoleOrg?.applicationRole?.length > 1 ? (
              <MultiSelect
                name="applicationId"
                label="Aplikasi"
                placeholder="Pilih aplikasi"
                searchable
                nothingFoundMessage="Aplikasi tidak ditemukan"
                data={applicationList}
                onChange={(e) => setSelectedApplicationId(e)}
                error={validationForm?.applicationId?.isError ? `${validationForm?.applicationId?.message}` : ""}
                value={selectedApplicationId}
                withAsterisk
              />
            ) : (
              <Select
                name="applicationId"
                label="Aplikasi"
                placeholder="Pilih aplikasi"
                searchable
                nothingFoundMessage="Aplikasi tidak ditemukan"
                data={applicationList}
                onChange={(e) => setSelectedApplicationId(e)}
                error={validationForm?.applicationId?.isError ? `${validationForm?.applicationId?.message}` : ""}
                value={selectedApplicationId}
                withAsterisk
              />
            )}
          </Box>

          <Box mb="md">
            <Flex justify="flex-start">
              <Text fz={14} mx={3} mr={8}>
                Active :{" "}
              </Text>
              <Switch onLabel="YES" offLabel="NO" checked={formData.isActive} onChange={handleSwitch} />
            </Flex>
          </Box>
        </>
      ) : (
        <Box>
          <FormSkeleton total={3} />
        </Box>
      )}
      <Box mt={20}>
        <Flex justify="flex-end">
          <Group>
            <Button variant="outline" color="indigo.9" onClick={onCloseModal}>
              Tutup
            </Button>
            <Button loading={loadingForm} variant="filled" color="indigo.9" onClick={() => handleSubmit(formData)}>
              Simpan
            </Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  );
};

export default FormRoleOrg;
