import React, { useEffect, useState } from "react";
import { assignFeatureOrg, getFeatureByApp, getListFeatureOrg } from "../../../services/featureOrgaization";
import { Box, Button, Flex, Group, ScrollArea, Skeleton, Switch, Text } from "@mantine/core";
import { notificationError, notificationSuccess } from "../../ui/Notification";

const FormFeatureOrg = ({ params, onCloseModal, handleBackApp }) => {
  const appId = params.applicationId;
  const orgId = params.organizationId;
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [featureList, setFeatureList] = useState(null);
  const [selectedFeatureId, setSelectedFeatureId] = useState([]);

  const handleGetFeatureList = async () => {
    setLoading(true);
    try {
      const response = await getFeatureByApp(appId);
      setFeatureList(response);
      const detailRoleFeature = await getListFeatureOrg({ organizationId: orgId, applicationId: appId });
      const dataFeature = Object.values(detailRoleFeature);
      const featureId = [];
      for (let i = 0; i < dataFeature.length; i++) {
        featureId.push(dataFeature[i]);
      }
      setSelectedFeatureId(featureId);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleGetFeatureList();
    // eslint-disable-next-line
  }, []);

  const selectPermission = (eventChecked, valuePermission) => {
    console.log(valuePermission);
    const data = selectedFeatureId;
    if (eventChecked) {
      const dataSelected = {
        id: valuePermission.id,
        name: valuePermission.name,
        alias: valuePermission.alias,
      };
      data.push(dataSelected);
      setSelectedFeatureId(selectedFeatureId);
    } else {
      for (let i = 0; i < selectedFeatureId.length; i++) {
        if (selectedFeatureId[i].id === valuePermission.id) {
          data.splice(i, 1);
        }
      }
      setSelectedFeatureId([...selectedFeatureId]);
    }
  };

  const mappingFeatureState = (stateFeature) => {
    const mappingDataPermission = stateFeature?.map((val) => {
      return (
        <Flex justify="space-between" align="center" mb={12}>
          <Text fz={14} fw="600">
            {val.name}
          </Text>
          <Switch onLabel="ON" offLabel="OFF" checked={selectedFeatureId.find((value) => value.id === val.id)} onChange={(e) => selectPermission(e.currentTarget.checked, val)} />
        </Flex>
      );
    });
    return mappingDataPermission;
  };

  const mappingPermissionRole = (dataList) =>
    Object.keys(dataList).map((index) => {
      return <Box>{mappingFeatureState(dataList)[index]}</Box>;
    });

  const submitFeature = async (dataFeature) => {
    setLoadingSubmit(true);
    const payload = {
      applicationId: appId,
      organizationId: orgId,
      featureIds: [],
    };
    const featureId = dataFeature.map((val) => {
      return val.id;
    });
    payload.featureIds = featureId;
    try {
      const response = await assignFeatureOrg(payload);
      if (response) {
        notificationSuccess("Update Feature Berhasil", `Anda telah merubah akses role Feature ini`);
      }
    } catch (error) {
      console.log(error);
      notificationError("Update Feature Gagal", `Perubahan role Feature ini gagal di update`);
    } finally {
      setLoadingSubmit(false);
      onCloseModal();
    }
  };

  const loadingFeature = (number) =>
    Array.from({ length: number }, (_, i) => {
      return (
        <Box key={i}>
          <Skeleton height={40} width="100%" radius="md" mb="md" />
        </Box>
      );
    });

  return (
    <Box my={10}>
      <ScrollArea my={16} h={500} offsetScrollbars scrollbarSize={8}>
        {loading ? (
          loadingFeature(8)
        ) : (
          <Box p={6} variant="separated">
            {mappingPermissionRole(featureList)}
          </Box>
        )}
      </ScrollArea>
      <Box mt={10}>
        <Flex justify="space-between">
          <Group>
            <Button variant="outline" color="indigo.9" onClick={handleBackApp}>
              Kembali
            </Button>
          </Group>
          <Group>
            <Button variant="outline" color="indigo.9" onClick={onCloseModal}>
              Tutup
            </Button>
            <Button loading={loadingSubmit} variant="filled" color="indigo.9" onClick={() => submitFeature(selectedFeatureId)}>
              Simpan
            </Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  );
};

export default FormFeatureOrg;
