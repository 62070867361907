import { IconApi, IconBox, IconCategory, IconHistory, IconLayoutDashboard, IconUser, IconUserCheck } from "@tabler/icons-react";
import Dashboard from "./components/pages/dashboard";
import Auth from "./pages/authentication";
import User from "./pages/User";
import { MENU_PERMISSION } from "./plugins/permissions";
import AuthSuperAdmin from "./pages/authenticationSuperAdmin";
import Organization from "./pages/Organization";
import Application from "./pages/Application";
import UserOrg from "./pages/UserOrg";
import RoleDefault from "./pages/RoleDefault";
import RoleOrg from "./pages/RoleOrg";
import PermissionDefault from "./pages/PermissionDefault";
import { IconListCheck } from "@tabler/icons-react";
import PermissionDefaultByApp from "./pages/PermissionDefault/Detail";
import SettingItem from "./pages/SettingItem";
import { IconSettings } from "@tabler/icons-react";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import HistoryWeb from "./pages/HistoryWeb";
import Feature from "./pages/Feature";
import DetailFeature from "./pages/Feature/Detail";
import AccountLogin from "./pages/AccountLogin";
import ForgotPasswordAccount from "./pages/ForgotPasswordAccount";
import ResetPasswordAccount from "./pages/ResetPasswordAccount";
import DatabaseDefault from "./pages/DatabaseDefault";
import { IconDatabase } from "@tabler/icons-react";
import DatabaseApplication from "./pages/DatabaseApplication";
import { IconFileDatabase } from "@tabler/icons-react";
import AppAPIKey from "./pages/AppAPIKey";

const routes = [
  {
    name: "dashboard",
    route: "/",
    hasChildren: false,
    component: <Dashboard />,
    icon: <IconLayoutDashboard stroke={1.5} size={20} />,
    type: "route",
    permission: MENU_PERMISSION.dashboard,
  },
  {
    name: "User & Role",
    type: "title",
    adminPermission: MENU_PERMISSION.org,
  },
  {
    name: "user",
    route: "/user",
    hasChildren: false,
    component: <User />,
    icon: <IconUser stroke={1.5} size={20} />,
    type: "route",
    adminPermission: MENU_PERMISSION.org,
  },
  {
    name: "role",
    route: "/role",
    hasChildren: false,
    component: <RoleOrg />,
    icon: <IconUserCheck stroke={1.5} size={20} />,
    type: "route",
    adminPermission: MENU_PERMISSION.org,
  },
  {
    name: "history login",
    route: "/history-login",
    hasChildren: false,
    component: <HistoryWeb />,
    icon: <IconHistory stroke={1.5} size={20} />,
    type: "route",
    adminPermission: MENU_PERMISSION.org,
  },
  {
    name: "User & Role",
    type: "title",
    adminPermission: MENU_PERMISSION.system,
    superadminOrgPermission: MENU_PERMISSION.system,
  },
  {
    name: "users",
    route: "/users",
    hasChildren: false,
    component: <UserOrg />,
    icon: <IconUser stroke={1.5} size={20} />,
    type: "route",
    adminPermission: MENU_PERMISSION.system,
    superadminOrgPermission: MENU_PERMISSION.system,
  },
  {
    name: "role default",
    route: "/role-default",
    hasChildren: false,
    component: <RoleDefault />,
    icon: <IconUserCheck stroke={1.5} size={20} />,
    type: "route",
    adminPermission: MENU_PERMISSION.system,
    superadminOrgPermission: MENU_PERMISSION.system,
  },
  {
    name: "permission",
    route: "/permission",
    hasChildren: true,
    children: [
      {
        name: "index",
        route: null,
        component: <PermissionDefault />,
      },
      {
        name: "KLHK Report Parameter",
        route: "/permission/:id",
        component: <PermissionDefaultByApp />,
      },
    ],
    icon: <IconListCheck stroke={1.5} size={18} />,
    type: "route",
    adminPermission: MENU_PERMISSION.system,
    superadminOrgPermission: MENU_PERMISSION.system,
  },
  {
    name: "Organization",
    type: "title",
    adminPermission: MENU_PERMISSION.system,
    superadminOrgPermission: MENU_PERMISSION.systemOrg,
  },
  {
    name: "application",
    route: "/application",
    hasChildren: false,
    component: <Application />,
    icon: <IconBox stroke={1.5} size={20} />,
    type: "route",
    adminPermission: MENU_PERMISSION.system,
    superadminOrgPermission: MENU_PERMISSION.system,
  },
  {
    name: "organization",
    route: "/organization",
    hasChildren: false,
    component: <Organization />,
    icon: <IconUser stroke={1.5} size={20} />,
    type: "route",
    adminPermission: MENU_PERMISSION.system,
    superadminOrgPermission: MENU_PERMISSION.systemOrg,
  },
  {
    name: "setting item",
    route: "/setting-item",
    hasChildren: false,
    component: <SettingItem />,
    icon: <IconSettings stroke={1.5} size={20} />,
    type: "route",
    adminPermission: MENU_PERMISSION.system,
    superadminOrgPermission: MENU_PERMISSION.system,
  },
  {
    name: "features",
    route: "/features",
    hasChildren: true,
    children: [
      {
        name: "index",
        route: null,
        component: <Feature />,
      },
      {
        name: "KLHK Report Parameter",
        route: "/features/:id/:id",
        component: <DetailFeature />,
      },
    ],
    icon: <IconCategory stroke={1.5} size={18} />,
    type: "route",
    adminPermission: MENU_PERMISSION.system,
    superadminOrgPermission: MENU_PERMISSION.system,
  },
  {
    name: "database",
    route: "/database",
    hasChildren: false,
    component: <DatabaseDefault />,
    icon: <IconDatabase stroke={1.5} size={20} />,
    type: "route",
    adminPermission: MENU_PERMISSION.system,
    superadminOrgPermission: MENU_PERMISSION.system,
  },
  {
    name: "database app",
    route: "/database-app",
    hasChildren: false,
    component: <DatabaseApplication />,
    icon: <IconFileDatabase stroke={1.5} size={20} />,
    type: "route",
    adminPermission: MENU_PERMISSION.system,
    superadminOrgPermission: MENU_PERMISSION.system,
  },
  {
    name: "app api key",
    route: "/app-api-key",
    hasChildren: false,
    component: <AppAPIKey />,
    icon: <IconApi stroke={1.5} size={20} />,
    type: "route",
    adminPermission: MENU_PERMISSION.system,
    superadminOrgPermission: MENU_PERMISSION.system,
  },
];

const routesAuth = [
  {
    name: "authentication",
    route: "/authentication/sign-in",
    hasChildren: false,
    component: <Auth />,
  },
];

const routesAuthSuperAdmin = [
  {
    name: "authentication",
    route: "/authentication/sign-in/superadmin",
    hasChildren: false,
    component: <AuthSuperAdmin />,
  },
];

const routesForgotPassword = [
  {
    name: "authentication",
    route: "/authentication/sign-in/forgot-password",
    hasChildren: false,
    component: <ForgotPassword />,
  },
];

const routesForgotPasswordAccount = [
  {
    name: "authentication",
    route: "/authentication/account/forgot-password/:id",
    hasChildren: false,
    component: <ForgotPasswordAccount />,
  },
];

const routesResetPassword = [
  {
    name: "authentication",
    route: "/authentication/reset-password/:token",
    hasChildren: false,
    component: <ResetPassword />,
  },
];

const routesResetPasswordAccount = [
  {
    name: "authentication",
    route: "/authentication/account/reset-password/:token",
    hasChildren: false,
    component: <ResetPasswordAccount />,
  },
];

const routesAccountLogin = [
  {
    name: "authentication",
    route: "authentication/account/:id",
    hasChildren: false,
    component: <AccountLogin />,
  },
];

export { routes, routesAuth, routesAuthSuperAdmin, routesForgotPassword, routesForgotPasswordAccount, routesResetPassword, routesResetPasswordAccount, routesAccountLogin };
