import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, Flex, Group, PasswordInput, Popover, Progress, Switch, Text, TextInput } from "@mantine/core";
import { notificationError, notificationSuccess } from "../../ui/Notification";
import { updateUserOrg } from "../../../services/usersOrg";
import { validation } from "../../../plugins/validation";
import PasswordRequired from "../../ui/PasswordRequired";

const defaultVal = {
  fullName: "",
  email: "",
  isActive: false,
  password: "",
  verifyPassword: "",
};

const formValidation = {
  fullName: {
    isError: false,
    message: "",
  },
  email: {
    isError: false,
    message: "",
  },
  isActive: {
    isError: false,
    message: "",
  },
  password: {
    isError: false,
    message: "",
  },
  verifyPassword: {
    isError: false,
    message: "",
  },
};

const requirements = [
  { re: /[0-9]/, label: "Password harus mencantumkan angka" },
  { re: /[a-z]/, label: "Password harus mencantumkan huruf kecil" },
  { re: /[A-Z]/, label: "Password harus mencantumkan huruf besar" },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Password harus mencantumkan simbol" },
];

function getStrength(password) {
  let multiplier = password?.length > 7 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}

const FormUserOrg = ({ dataUserOrg, onCloseModal, reloadList }) => {
  const [loadingForm, setLoadingForm] = useState(false);
  const [formData, setFormData] = useState(defaultVal);
  const [validationForm, setValidationForm] = useState(formValidation);
  const [userPassword, setUserPassword] = useState(false);
  const [popoverPassword, setPopoverPassword] = useState(false);
  const checkPassword = requirements.map((val, index) => <PasswordRequired key={index} label={val.label} meets={val.re.test(formData.password)} />);
  const strengthPassword = getStrength(formData?.password);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSwitch = () => {
    setFormData({ ...formData, isActive: !formData.isActive });
  };

  const progressBar = Array(4)
    .fill(0)
    .map((_, index) => {
      return (
        <Progress
          styles={{ section: { transitionDuration: "0ms" } }}
          value={Object?.values(formData?.password).length > 0 && index === 0 ? 100 : strengthPassword >= ((index + 1) / 4) * 100 ? 100 : 0}
          color={strengthPassword > 80 ? "teal" : strengthPassword > 50 ? "yellow" : "red"}
          key={index}
          size={4}
        />
      );
    });

  useEffect(() => {
    if (dataUserOrg !== null) {
      handleSetForm(dataUserOrg);
    }
    // eslint-disable-next-line
  }, [dataUserOrg]);

  const handleSetForm = (dataUserOrg) => {
    const dataDetail = {
      fullName: dataUserOrg?.fullName,
      email: dataUserOrg?.email,
      isActive: dataUserOrg?.isActive,
      password: formData?.password,
    };
    setFormData(dataDetail);
  };

  const handleSubmit = async (data) => {
    let methodFunction = null;
    let titleMessageSuccess = "";
    let captionMessageSuccess = "";
    let titleMessageError = "";
    let captionMessageError = "";
    setLoadingForm(true);
    setValidationForm(formValidation);
    const payload = {
      fullName: data.fullName,
      email: data.email,
      isActive: Boolean(data.isActive),
      // password: data.password,
    };
    if (!dataUserOrg) {
      payload.password = data.password;
    }
    if (userPassword === true) {
      payload.password = data.password;
    }
    const isError = validation(payload, setValidationForm);
    if (isError) {
      setLoadingForm(false);
      return;
    }
    if (dataUserOrg !== null) {
      methodFunction = updateUserOrg(dataUserOrg?.id, payload);
      titleMessageSuccess = "Update User Berhasil";
      captionMessageSuccess = "Anda telah berhasil mengupdate data User";
      titleMessageError = "Gagal Mengupdate User";
      captionMessageError = "Silahkan cek kembali form anda";
    }
    try {
      const response = await methodFunction;
      if (response) {
        onCloseModal();
        reloadList();
        notificationSuccess(titleMessageSuccess, captionMessageSuccess);
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      notificationError(titleMessageError, `${Object.keys(errorMessage) ? errorMessage : captionMessageError}`);
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el,
              },
            }));
          }
        });
      });
    } finally {
      setLoadingForm(false);
    }
  };

  const FormPassword = () => {
    // if (setPassword) {
    return (
      <Box>
        <Box mb="md">
          <Popover opened={popoverPassword} position="bottom" width="target" transitionProps={{ transition: "pop" }}>
            <Popover.Target>
              <Box onFocusCapture={() => setPopoverPassword(true)} onBlurCapture={() => setPopoverPassword(false)}>
                <PasswordInput
                  name="password"
                  value={formData.password}
                  placeholder="Masukkan password"
                  label="Password"
                  error={validationForm.password.isError ? `${validationForm.password.message}` : ""}
                  onChange={handleChange}
                  withAsterisk
                />
              </Box>
            </Popover.Target>
            <Popover.Dropdown>
              <Group gap={5} grow mt="xs" mb="md">
                {progressBar}
              </Group>
              <PasswordRequired label="Password harus lebih dari 7 karakter" meets={formData.password.length > 7} />
              {checkPassword}
            </Popover.Dropdown>
          </Popover>
        </Box>
        <Box mb="md">
          <PasswordInput
            value={formData.verifyPassword}
            name="verifyPassword"
            placeholder="Ulangi password anda"
            label="Konfirmasi Password"
            error={validationForm.verifyPassword.isError ? validationForm.verifyPassword.message : ""}
            onChange={handleChange}
            withAsterisk
          />
        </Box>
      </Box>
    );
    // }
  };

  return (
    <Box>
      <Box mb="md">
        <TextInput
          name="fullName"
          value={formData.fullName}
          label="Nama Lengkap"
          placeholder="Masukkan nama lengkap"
          error={validationForm.fullName.isError ? `${validationForm.fullName.message}` : ""}
          onChange={handleChange}
          withAsterisk
        />
      </Box>
      <Box mb="md">
        <TextInput name="email" value={formData.email} label="Email" placeholder="Masukkan nama organisasi" error={validationForm.email.isError ? `${validationForm.email.message}` : ""} onChange={handleChange} withAsterisk />
      </Box>
      {/* <Box mb="md">
        <TextInput disabled name="role" value={dataUserOrg?.role?.name} label="Role User" placeholder="Role user" withAsterisk />
      </Box> */}
      {dataUserOrg && (
        <Box mb="md">
          <Checkbox checked={userPassword} onChange={(e) => setUserPassword(e.currentTarget.checked)} label="Atur Password" />
        </Box>
      )}
      {(userPassword === true || !dataUserOrg) && <>{FormPassword()}</>}
      <Box mb="md">
        <Flex justify="flex-start">
          <Text fz={14} mx={3} mr={8}>
            Active :{" "}
          </Text>
          <Switch onLabel="YES" offLabel="NO" checked={formData.isActive} onChange={handleSwitch} />
        </Flex>
      </Box>

      <Box mt={20}>
        <Flex justify="flex-end">
          <Group>
            <Button variant="outline" color="indigo.9" onClick={onCloseModal}>
              Tutup
            </Button>
            <Button loading={loadingForm} variant="filled" color="indigo.9" onClick={() => handleSubmit(formData)}>
              Simpan
            </Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  );
};

export default FormUserOrg;
